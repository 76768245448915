"use strict";

var cov_ga27gi738 = function () {
  var path = "/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/authMFA/mfaController.js",
      hash = "be37c44960469b244be2e5a178b8f7fbe84b1a51",
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = "__coverage__",
      coverageData = {
    path: "/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/views/authMFA/mfaController.js",
    statementMap: {
      "0": {
        start: {
          line: 1,
          column: 0
        },
        end: {
          line: 159,
          column: 3
        }
      },
      "1": {
        start: {
          line: 2,
          column: 15
        },
        end: {
          line: 2,
          column: 23
        }
      },
      "2": {
        start: {
          line: 4,
          column: 2
        },
        end: {
          line: 4,
          column: 30
        }
      },
      "3": {
        start: {
          line: 6,
          column: 2
        },
        end: {
          line: 6,
          column: 31
        }
      },
      "4": {
        start: {
          line: 8,
          column: 2
        },
        end: {
          line: 8,
          column: 34
        }
      },
      "5": {
        start: {
          line: 9,
          column: 2
        },
        end: {
          line: 9,
          column: 35
        }
      },
      "6": {
        start: {
          line: 12,
          column: 2
        },
        end: {
          line: 12,
          column: 31
        }
      },
      "7": {
        start: {
          line: 13,
          column: 2
        },
        end: {
          line: 13,
          column: 31
        }
      },
      "8": {
        start: {
          line: 15,
          column: 2
        },
        end: {
          line: 15,
          column: 28
        }
      },
      "9": {
        start: {
          line: 16,
          column: 2
        },
        end: {
          line: 16,
          column: 27
        }
      },
      "10": {
        start: {
          line: 17,
          column: 2
        },
        end: {
          line: 17,
          column: 16
        }
      },
      "11": {
        start: {
          line: 18,
          column: 2
        },
        end: {
          line: 18,
          column: 24
        }
      },
      "12": {
        start: {
          line: 21,
          column: 4
        },
        end: {
          line: 21,
          column: 30
        }
      },
      "13": {
        start: {
          line: 24,
          column: 2
        },
        end: {
          line: 58,
          column: 3
        }
      },
      "14": {
        start: {
          line: 25,
          column: 4
        },
        end: {
          line: 25,
          column: 32
        }
      },
      "15": {
        start: {
          line: 26,
          column: 4
        },
        end: {
          line: 57,
          column: 7
        }
      },
      "16": {
        start: {
          line: 27,
          column: 6
        },
        end: {
          line: 27,
          column: 34
        }
      },
      "17": {
        start: {
          line: 28,
          column: 6
        },
        end: {
          line: 28,
          column: 52
        }
      },
      "18": {
        start: {
          line: 29,
          column: 6
        },
        end: {
          line: 29,
          column: 21
        }
      },
      "19": {
        start: {
          line: 31,
          column: 6
        },
        end: {
          line: 41,
          column: 7
        }
      },
      "20": {
        start: {
          line: 32,
          column: 8
        },
        end: {
          line: 32,
          column: 35
        }
      },
      "21": {
        start: {
          line: 33,
          column: 8
        },
        end: {
          line: 35,
          column: 9
        }
      },
      "22": {
        start: {
          line: 34,
          column: 10
        },
        end: {
          line: 34,
          column: 50
        }
      },
      "23": {
        start: {
          line: 37,
          column: 8
        },
        end: {
          line: 40,
          column: 9
        }
      },
      "24": {
        start: {
          line: 38,
          column: 11
        },
        end: {
          line: 38,
          column: 51
        }
      },
      "25": {
        start: {
          line: 39,
          column: 11
        },
        end: {
          line: 39,
          column: 33
        }
      },
      "26": {
        start: {
          line: 60,
          column: 2
        },
        end: {
          line: 65,
          column: 3
        }
      },
      "27": {
        start: {
          line: 61,
          column: 4
        },
        end: {
          line: 64,
          column: 7
        }
      },
      "28": {
        start: {
          line: 62,
          column: 6
        },
        end: {
          line: 62,
          column: 48
        }
      },
      "29": {
        start: {
          line: 63,
          column: 6
        },
        end: {
          line: 63,
          column: 60
        }
      },
      "30": {
        start: {
          line: 67,
          column: 2
        },
        end: {
          line: 71,
          column: 3
        }
      },
      "31": {
        start: {
          line: 68,
          column: 4
        },
        end: {
          line: 70,
          column: 7
        }
      },
      "32": {
        start: {
          line: 69,
          column: 6
        },
        end: {
          line: 69,
          column: 21
        }
      },
      "33": {
        start: {
          line: 73,
          column: 2
        },
        end: {
          line: 97,
          column: 3
        }
      },
      "34": {
        start: {
          line: 74,
          column: 4
        },
        end: {
          line: 74,
          column: 47
        }
      },
      "35": {
        start: {
          line: 75,
          column: 4
        },
        end: {
          line: 79,
          column: 5
        }
      },
      "36": {
        start: {
          line: 76,
          column: 6
        },
        end: {
          line: 76,
          column: 31
        }
      },
      "37": {
        start: {
          line: 77,
          column: 6
        },
        end: {
          line: 77,
          column: 37
        }
      },
      "38": {
        start: {
          line: 78,
          column: 6
        },
        end: {
          line: 78,
          column: 13
        }
      },
      "39": {
        start: {
          line: 81,
          column: 4
        },
        end: {
          line: 96,
          column: 7
        }
      },
      "40": {
        start: {
          line: 82,
          column: 6
        },
        end: {
          line: 82,
          column: 49
        }
      },
      "41": {
        start: {
          line: 83,
          column: 6
        },
        end: {
          line: 83,
          column: 42
        }
      },
      "42": {
        start: {
          line: 85,
          column: 6
        },
        end: {
          line: 85,
          column: 38
        }
      },
      "43": {
        start: {
          line: 86,
          column: 6
        },
        end: {
          line: 95,
          column: 7
        }
      },
      "44": {
        start: {
          line: 87,
          column: 8
        },
        end: {
          line: 87,
          column: 33
        }
      },
      "45": {
        start: {
          line: 88,
          column: 8
        },
        end: {
          line: 88,
          column: 29
        }
      },
      "46": {
        start: {
          line: 89,
          column: 8
        },
        end: {
          line: 89,
          column: 37
        }
      },
      "47": {
        start: {
          line: 93,
          column: 8
        },
        end: {
          line: 93,
          column: 32
        }
      },
      "48": {
        start: {
          line: 94,
          column: 8
        },
        end: {
          line: 94,
          column: 15
        }
      },
      "49": {
        start: {
          line: 99,
          column: 2
        },
        end: {
          line: 121,
          column: 4
        }
      },
      "50": {
        start: {
          line: 100,
          column: 4
        },
        end: {
          line: 100,
          column: 39
        }
      },
      "51": {
        start: {
          line: 101,
          column: 4
        },
        end: {
          line: 105,
          column: 5
        }
      },
      "52": {
        start: {
          line: 102,
          column: 6
        },
        end: {
          line: 102,
          column: 31
        }
      },
      "53": {
        start: {
          line: 103,
          column: 6
        },
        end: {
          line: 103,
          column: 37
        }
      },
      "54": {
        start: {
          line: 104,
          column: 6
        },
        end: {
          line: 104,
          column: 13
        }
      },
      "55": {
        start: {
          line: 107,
          column: 4
        },
        end: {
          line: 118,
          column: 7
        }
      },
      "56": {
        start: {
          line: 108,
          column: 6
        },
        end: {
          line: 108,
          column: 41
        }
      },
      "57": {
        start: {
          line: 109,
          column: 6
        },
        end: {
          line: 109,
          column: 38
        }
      },
      "58": {
        start: {
          line: 110,
          column: 6
        },
        end: {
          line: 117,
          column: 7
        }
      },
      "59": {
        start: {
          line: 111,
          column: 8
        },
        end: {
          line: 111,
          column: 37
        }
      },
      "60": {
        start: {
          line: 115,
          column: 8
        },
        end: {
          line: 115,
          column: 32
        }
      },
      "61": {
        start: {
          line: 116,
          column: 8
        },
        end: {
          line: 116,
          column: 15
        }
      },
      "62": {
        start: {
          line: 123,
          column: 2
        },
        end: {
          line: 125,
          column: 4
        }
      },
      "63": {
        start: {
          line: 124,
          column: 4
        },
        end: {
          line: 124,
          column: 33
        }
      },
      "64": {
        start: {
          line: 127,
          column: 2
        },
        end: {
          line: 132,
          column: 4
        }
      },
      "65": {
        start: {
          line: 128,
          column: 4
        },
        end: {
          line: 128,
          column: 29
        }
      },
      "66": {
        start: {
          line: 129,
          column: 4
        },
        end: {
          line: 129,
          column: 29
        }
      },
      "67": {
        start: {
          line: 130,
          column: 4
        },
        end: {
          line: 130,
          column: 32
        }
      },
      "68": {
        start: {
          line: 131,
          column: 4
        },
        end: {
          line: 131,
          column: 33
        }
      },
      "69": {
        start: {
          line: 134,
          column: 2
        },
        end: {
          line: 137,
          column: 4
        }
      },
      "70": {
        start: {
          line: 135,
          column: 2
        },
        end: {
          line: 135,
          column: 47
        }
      },
      "71": {
        start: {
          line: 136,
          column: 2
        },
        end: {
          line: 136,
          column: 26
        }
      },
      "72": {
        start: {
          line: 139,
          column: 21
        },
        end: {
          line: 147,
          column: 3
        }
      },
      "73": {
        start: {
          line: 140,
          column: 4
        },
        end: {
          line: 140,
          column: 38
        }
      },
      "74": {
        start: {
          line: 140,
          column: 31
        },
        end: {
          line: 140,
          column: 38
        }
      },
      "75": {
        start: {
          line: 141,
          column: 24
        },
        end: {
          line: 141,
          column: 76
        }
      },
      "76": {
        start: {
          line: 142,
          column: 4
        },
        end: {
          line: 146,
          column: 5
        }
      },
      "77": {
        start: {
          line: 143,
          column: 8
        },
        end: {
          line: 145,
          column: 11
        }
      },
      "78": {
        start: {
          line: 144,
          column: 12
        },
        end: {
          line: 144,
          column: 41
        }
      },
      "79": {
        start: {
          line: 149,
          column: 2
        },
        end: {
          line: 149,
          column: 38
        }
      },
      "80": {
        start: {
          line: 152,
          column: 2
        },
        end: {
          line: 154,
          column: 5
        }
      },
      "81": {
        start: {
          line: 153,
          column: 4
        },
        end: {
          line: 153,
          column: 41
        }
      },
      "82": {
        start: {
          line: 158,
          column: 2
        },
        end: {
          line: 158,
          column: 9
        }
      }
    },
    fnMap: {
      "0": {
        name: "(anonymous_0)",
        decl: {
          start: {
            line: 1,
            column: 49
          },
          end: {
            line: 1,
            column: 50
          }
        },
        loc: {
          start: {
            line: 1,
            column: 187
          },
          end: {
            line: 159,
            column: 1
          }
        },
        line: 1
      },
      "1": {
        name: "init",
        decl: {
          start: {
            line: 20,
            column: 11
          },
          end: {
            line: 20,
            column: 15
          }
        },
        loc: {
          start: {
            line: 20,
            column: 18
          },
          end: {
            line: 22,
            column: 3
          }
        },
        line: 20
      },
      "2": {
        name: "(anonymous_2)",
        decl: {
          start: {
            line: 24,
            column: 28
          },
          end: {
            line: 24,
            column: 29
          }
        },
        loc: {
          start: {
            line: 24,
            column: 38
          },
          end: {
            line: 58,
            column: 3
          }
        },
        line: 24
      },
      "3": {
        name: "(anonymous_3)",
        decl: {
          start: {
            line: 26,
            column: 56
          },
          end: {
            line: 26,
            column: 57
          }
        },
        loc: {
          start: {
            line: 26,
            column: 66
          },
          end: {
            line: 57,
            column: 5
          }
        },
        line: 26
      },
      "4": {
        name: "(anonymous_4)",
        decl: {
          start: {
            line: 60,
            column: 21
          },
          end: {
            line: 60,
            column: 22
          }
        },
        loc: {
          start: {
            line: 60,
            column: 32
          },
          end: {
            line: 65,
            column: 3
          }
        },
        line: 60
      },
      "5": {
        name: "(anonymous_5)",
        decl: {
          start: {
            line: 61,
            column: 49
          },
          end: {
            line: 61,
            column: 50
          }
        },
        loc: {
          start: {
            line: 61,
            column: 59
          },
          end: {
            line: 64,
            column: 5
          }
        },
        line: 61
      },
      "6": {
        name: "(anonymous_6)",
        decl: {
          start: {
            line: 67,
            column: 24
          },
          end: {
            line: 67,
            column: 25
          }
        },
        loc: {
          start: {
            line: 67,
            column: 34
          },
          end: {
            line: 71,
            column: 3
          }
        },
        line: 67
      },
      "7": {
        name: "(anonymous_7)",
        decl: {
          start: {
            line: 68,
            column: 51
          },
          end: {
            line: 68,
            column: 52
          }
        },
        loc: {
          start: {
            line: 68,
            column: 61
          },
          end: {
            line: 70,
            column: 5
          }
        },
        line: 68
      },
      "8": {
        name: "(anonymous_8)",
        decl: {
          start: {
            line: 73,
            column: 26
          },
          end: {
            line: 73,
            column: 27
          }
        },
        loc: {
          start: {
            line: 73,
            column: 36
          },
          end: {
            line: 97,
            column: 3
          }
        },
        line: 73
      },
      "9": {
        name: "(anonymous_9)",
        decl: {
          start: {
            line: 81,
            column: 77
          },
          end: {
            line: 81,
            column: 78
          }
        },
        loc: {
          start: {
            line: 81,
            column: 91
          },
          end: {
            line: 96,
            column: 5
          }
        },
        line: 81
      },
      "10": {
        name: "(anonymous_10)",
        decl: {
          start: {
            line: 99,
            column: 20
          },
          end: {
            line: 99,
            column: 21
          }
        },
        loc: {
          start: {
            line: 99,
            column: 31
          },
          end: {
            line: 121,
            column: 3
          }
        },
        line: 99
      },
      "11": {
        name: "(anonymous_11)",
        decl: {
          start: {
            line: 107,
            column: 80
          },
          end: {
            line: 107,
            column: 81
          }
        },
        loc: {
          start: {
            line: 107,
            column: 94
          },
          end: {
            line: 118,
            column: 5
          }
        },
        line: 107
      },
      "12": {
        name: "(anonymous_12)",
        decl: {
          start: {
            line: 123,
            column: 23
          },
          end: {
            line: 123,
            column: 24
          }
        },
        loc: {
          start: {
            line: 123,
            column: 34
          },
          end: {
            line: 125,
            column: 3
          }
        },
        line: 123
      },
      "13": {
        name: "(anonymous_13)",
        decl: {
          start: {
            line: 127,
            column: 18
          },
          end: {
            line: 127,
            column: 19
          }
        },
        loc: {
          start: {
            line: 127,
            column: 40
          },
          end: {
            line: 132,
            column: 3
          }
        },
        line: 127
      },
      "14": {
        name: "(anonymous_14)",
        decl: {
          start: {
            line: 134,
            column: 22
          },
          end: {
            line: 134,
            column: 23
          }
        },
        loc: {
          start: {
            line: 134,
            column: 38
          },
          end: {
            line: 137,
            column: 3
          }
        },
        line: 134
      },
      "15": {
        name: "(anonymous_15)",
        decl: {
          start: {
            line: 139,
            column: 21
          },
          end: {
            line: 139,
            column: 22
          }
        },
        loc: {
          start: {
            line: 139,
            column: 37
          },
          end: {
            line: 147,
            column: 3
          }
        },
        line: 139
      },
      "16": {
        name: "(anonymous_16)",
        decl: {
          start: {
            line: 143,
            column: 22
          },
          end: {
            line: 143,
            column: 23
          }
        },
        loc: {
          start: {
            line: 143,
            column: 33
          },
          end: {
            line: 145,
            column: 9
          }
        },
        line: 143
      },
      "17": {
        name: "(anonymous_17)",
        decl: {
          start: {
            line: 152,
            column: 25
          },
          end: {
            line: 152,
            column: 26
          }
        },
        loc: {
          start: {
            line: 152,
            column: 36
          },
          end: {
            line: 154,
            column: 3
          }
        },
        line: 152
      }
    },
    branchMap: {
      "0": {
        loc: {
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        },
        type: "if",
        locations: [{
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        }, {
          start: {
            line: 31,
            column: 6
          },
          end: {
            line: 41,
            column: 7
          }
        }],
        line: 31
      },
      "1": {
        loc: {
          start: {
            line: 33,
            column: 8
          },
          end: {
            line: 35,
            column: 9
          }
        },
        type: "if",
        locations: [{
          start: {
            line: 33,
            column: 8
          },
          end: {
            line: 35,
            column: 9
          }
        }, {
          start: {
            line: 33,
            column: 8
          },
          end: {
            line: 35,
            column: 9
          }
        }],
        line: 33
      },
      "2": {
        loc: {
          start: {
            line: 33,
            column: 11
          },
          end: {
            line: 33,
            column: 136
          }
        },
        type: "binary-expr",
        locations: [{
          start: {
            line: 33,
            column: 11
          },
          end: {
            line: 33,
            column: 46
          }
        }, {
          start: {
            line: 33,
            column: 50
          },
          end: {
            line: 33,
            column: 90
          }
        }, {
          start: {
            line: 33,
            column: 94
          },
          end: {
            line: 33,
            column: 136
          }
        }],
        line: 33
      },
      "3": {
        loc: {
          start: {
            line: 37,
            column: 8
          },
          end: {
            line: 40,
            column: 9
          }
        },
        type: "if",
        locations: [{
          start: {
            line: 37,
            column: 8
          },
          end: {
            line: 40,
            column: 9
          }
        }, {
          start: {
            line: 37,
            column: 8
          },
          end: {
            line: 40,
            column: 9
          }
        }],
        line: 37
      },
      "4": {
        loc: {
          start: {
            line: 75,
            column: 4
          },
          end: {
            line: 79,
            column: 5
          }
        },
        type: "if",
        locations: [{
          start: {
            line: 75,
            column: 4
          },
          end: {
            line: 79,
            column: 5
          }
        }, {
          start: {
            line: 75,
            column: 4
          },
          end: {
            line: 79,
            column: 5
          }
        }],
        line: 75
      },
      "5": {
        loc: {
          start: {
            line: 75,
            column: 8
          },
          end: {
            line: 75,
            column: 54
          }
        },
        type: "binary-expr",
        locations: [{
          start: {
            line: 75,
            column: 8
          },
          end: {
            line: 75,
            column: 24
          }
        }, {
          start: {
            line: 75,
            column: 28
          },
          end: {
            line: 75,
            column: 54
          }
        }],
        line: 75
      },
      "6": {
        loc: {
          start: {
            line: 86,
            column: 6
          },
          end: {
            line: 95,
            column: 7
          }
        },
        type: "if",
        locations: [{
          start: {
            line: 86,
            column: 6
          },
          end: {
            line: 95,
            column: 7
          }
        }, {
          start: {
            line: 86,
            column: 6
          },
          end: {
            line: 95,
            column: 7
          }
        }],
        line: 86
      },
      "7": {
        loc: {
          start: {
            line: 101,
            column: 4
          },
          end: {
            line: 105,
            column: 5
          }
        },
        type: "if",
        locations: [{
          start: {
            line: 101,
            column: 4
          },
          end: {
            line: 105,
            column: 5
          }
        }, {
          start: {
            line: 101,
            column: 4
          },
          end: {
            line: 105,
            column: 5
          }
        }],
        line: 101
      },
      "8": {
        loc: {
          start: {
            line: 101,
            column: 8
          },
          end: {
            line: 101,
            column: 54
          }
        },
        type: "binary-expr",
        locations: [{
          start: {
            line: 101,
            column: 8
          },
          end: {
            line: 101,
            column: 24
          }
        }, {
          start: {
            line: 101,
            column: 28
          },
          end: {
            line: 101,
            column: 54
          }
        }],
        line: 101
      },
      "9": {
        loc: {
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 117,
            column: 7
          }
        },
        type: "if",
        locations: [{
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 117,
            column: 7
          }
        }, {
          start: {
            line: 110,
            column: 6
          },
          end: {
            line: 117,
            column: 7
          }
        }],
        line: 110
      },
      "10": {
        loc: {
          start: {
            line: 140,
            column: 4
          },
          end: {
            line: 140,
            column: 38
          }
        },
        type: "if",
        locations: [{
          start: {
            line: 140,
            column: 4
          },
          end: {
            line: 140,
            column: 38
          }
        }, {
          start: {
            line: 140,
            column: 4
          },
          end: {
            line: 140,
            column: 38
          }
        }],
        line: 140
      },
      "11": {
        loc: {
          start: {
            line: 142,
            column: 4
          },
          end: {
            line: 146,
            column: 5
          }
        },
        type: "if",
        locations: [{
          start: {
            line: 142,
            column: 4
          },
          end: {
            line: 146,
            column: 5
          }
        }, {
          start: {
            line: 142,
            column: 4
          },
          end: {
            line: 146,
            column: 5
          }
        }],
        line: 142
      },
      "12": {
        loc: {
          start: {
            line: 142,
            column: 8
          },
          end: {
            line: 142,
            column: 58
          }
        },
        type: "binary-expr",
        locations: [{
          start: {
            line: 142,
            column: 8
          },
          end: {
            line: 142,
            column: 19
          }
        }, {
          start: {
            line: 142,
            column: 23
          },
          end: {
            line: 142,
            column: 58
          }
        }],
        line: 142
      }
    },
    s: {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      "6": 0,
      "7": 0,
      "8": 0,
      "9": 0,
      "10": 0,
      "11": 0,
      "12": 0,
      "13": 0,
      "14": 0,
      "15": 0,
      "16": 0,
      "17": 0,
      "18": 0,
      "19": 0,
      "20": 0,
      "21": 0,
      "22": 0,
      "23": 0,
      "24": 0,
      "25": 0,
      "26": 0,
      "27": 0,
      "28": 0,
      "29": 0,
      "30": 0,
      "31": 0,
      "32": 0,
      "33": 0,
      "34": 0,
      "35": 0,
      "36": 0,
      "37": 0,
      "38": 0,
      "39": 0,
      "40": 0,
      "41": 0,
      "42": 0,
      "43": 0,
      "44": 0,
      "45": 0,
      "46": 0,
      "47": 0,
      "48": 0,
      "49": 0,
      "50": 0,
      "51": 0,
      "52": 0,
      "53": 0,
      "54": 0,
      "55": 0,
      "56": 0,
      "57": 0,
      "58": 0,
      "59": 0,
      "60": 0,
      "61": 0,
      "62": 0,
      "63": 0,
      "64": 0,
      "65": 0,
      "66": 0,
      "67": 0,
      "68": 0,
      "69": 0,
      "70": 0,
      "71": 0,
      "72": 0,
      "73": 0,
      "74": 0,
      "75": 0,
      "76": 0,
      "77": 0,
      "78": 0,
      "79": 0,
      "80": 0,
      "81": 0,
      "82": 0
    },
    f: {
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "4": 0,
      "5": 0,
      "6": 0,
      "7": 0,
      "8": 0,
      "9": 0,
      "10": 0,
      "11": 0,
      "12": 0,
      "13": 0,
      "14": 0,
      "15": 0,
      "16": 0,
      "17": 0
    },
    b: {
      "0": [0, 0],
      "1": [0, 0],
      "2": [0, 0, 0],
      "3": [0, 0],
      "4": [0, 0],
      "5": [0, 0],
      "6": [0, 0],
      "7": [0, 0],
      "8": [0, 0],
      "9": [0, 0],
      "10": [0, 0],
      "11": [0, 0],
      "12": [0, 0]
    },
    _coverageSchema: "332fd63041d2c1bcb487cc26dd0d5f7d97098a6c"
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_ga27gi738.s[0]++;
angular.module("app").controller("mfaController", function ($scope, $rootScope, $state, $uibModal, $translate, $stateParams, $CRUDService, FileUploader, SweetAlert, $deltahttp, $document, $log) {
  cov_ga27gi738.f[0]++;

  var PATH = (cov_ga27gi738.s[1]++, "Projet");

  cov_ga27gi738.s[2]++;
  $scope.isMFAEnabled = false;

  cov_ga27gi738.s[3]++;
  $scope.isMultifactor = true;

  cov_ga27gi738.s[4]++;
  $scope.isAppMultifactor = false;
  cov_ga27gi738.s[5]++;
  $scope.isMailMultifactor = false;

  cov_ga27gi738.s[6]++;
  $scope.choiceMethode = false;
  cov_ga27gi738.s[7]++;
  $scope.isInfoVisible = false;

  cov_ga27gi738.s[8]++;
  $scope.qrCodeDataUri = '';
  cov_ga27gi738.s[9]++;
  $scope.error_mfa = false;
  cov_ga27gi738.s[10]++;
  $scope.mfa = {};
  cov_ga27gi738.s[11]++;
  $scope.userEmail = '';

  function init() {
    cov_ga27gi738.f[1]++;
    cov_ga27gi738.s[12]++;

    $scope.getconnectedUser();
  }

  cov_ga27gi738.s[13]++;
  $scope.getconnectedUser = function () {
    cov_ga27gi738.f[2]++;
    cov_ga27gi738.s[14]++;

    $scope.isMFAEnabled = false;
    cov_ga27gi738.s[15]++;
    $CRUDService.getAll(PATH, { get: "user_connected" }, function (data) {
      cov_ga27gi738.f[3]++;
      cov_ga27gi738.s[16]++;

      $scope.userConnected = data;
      cov_ga27gi738.s[17]++;
      $scope.userEmail = $scope.userConnected.LOGIN;
      cov_ga27gi738.s[18]++;
      $log.log(data);

      cov_ga27gi738.s[19]++;
      if ($scope.userConnected.MFA_ENABLED == 1) {
        cov_ga27gi738.b[0][0]++;
        cov_ga27gi738.s[20]++;

        $scope.isMFAEnabled = true;
        cov_ga27gi738.s[21]++;
        if ((cov_ga27gi738.b[2][0]++, $scope.userConnected.APP_MFA_SECRET) && (cov_ga27gi738.b[2][1]++, $scope.userConnected.APP_MFA_SECRET != '') && (cov_ga27gi738.b[2][2]++, $scope.userConnected.APP_MFA_SECRET != null)) {
          cov_ga27gi738.b[1][0]++;
          cov_ga27gi738.s[22]++;

          $scope.toggle(true, false, true, false);
        } else {
          cov_ga27gi738.b[1][1]++;
        }

        cov_ga27gi738.s[23]++;
        if ($scope.userConnected.EMAIL_MFA_CODE == 1) {
          cov_ga27gi738.b[3][0]++;
          cov_ga27gi738.s[24]++;

          $scope.toggle(true, false, false, true);
          cov_ga27gi738.s[25]++;
          $scope.getEmailCode();
        } else {
          cov_ga27gi738.b[3][1]++;
        }
      } else {
        cov_ga27gi738.b[0][1]++;
      }

      /*if($scope.userConnected.MFA_ENABLED == 0){
        $scope.isMFAEnabled = false;
      } else {
        $scope.isMFAEnabled = true;
        if($scope.userConnected.APP_MFA_SECRET && $scope.userConnected.APP_MFA_SECRET !='' && $scope.userConnected.APP_MFA_SECRET !=null){
          $scope.toggle(true, false, true, false);
        }
         if($scope.userConnected.EMAIL_MFA_CODE ==1) {
           $scope.toggle(true, false, false, true);
           $scope.getEmailCode();
        }
        
      }*/
    });
  };

  cov_ga27gi738.s[26]++;
  $scope.getQrCode = function () {
    cov_ga27gi738.f[4]++;
    cov_ga27gi738.s[27]++;

    $CRUDService.getAll(PATH, { get: "qr_code" }, function (data) {
      cov_ga27gi738.f[5]++;
      cov_ga27gi738.s[28]++;

      $scope.qrCodeDataUri = data.qrCodeDataUri;
      cov_ga27gi738.s[29]++;
      console.log("qrCodeDataUri : ", $scope.qrCodeDataUri);
    });
  };

  cov_ga27gi738.s[30]++;
  $scope.getEmailCode = function () {
    cov_ga27gi738.f[6]++;
    cov_ga27gi738.s[31]++;

    $CRUDService.getAll(PATH, { get: "send_code" }, function (data) {
      cov_ga27gi738.f[7]++;
      cov_ga27gi738.s[32]++;

      $log.log(data);
    });
  };

  cov_ga27gi738.s[33]++;
  $scope.checkEmailCode = function () {
    cov_ga27gi738.f[8]++;
    cov_ga27gi738.s[34]++;

    $log.log('code email : ', $scope.mfa.code);
    cov_ga27gi738.s[35]++;
    if ((cov_ga27gi738.b[5][0]++, !$scope.mfa.code) || (cov_ga27gi738.b[5][1]++, $scope.mfa.code.length < 6)) {
      cov_ga27gi738.b[4][0]++;
      cov_ga27gi738.s[36]++;

      $scope.error_mfa = false;
      cov_ga27gi738.s[37]++;
      $scope.error_mfa_length = true;
      cov_ga27gi738.s[38]++;
      return;
    } else {
      cov_ga27gi738.b[4][1]++;
    }

    cov_ga27gi738.s[39]++;
    $CRUDService.save(PATH, { action: 'check_code', value: $scope.mfa.code }, function (response) {
      cov_ga27gi738.f[9]++;
      cov_ga27gi738.s[40]++;

      $log.log('code saisie : ' + $scope.mfa.code);
      cov_ga27gi738.s[41]++;
      $log.log('code valid  : ' + response);

      cov_ga27gi738.s[42]++;
      $scope.error_mfa_length = false;
      cov_ga27gi738.s[43]++;
      if (response.valid) {
        cov_ga27gi738.b[6][0]++;
        cov_ga27gi738.s[44]++;

        $scope.error_mfa = false;
        cov_ga27gi738.s[45]++;
        $scope.mfa.code = '';
        cov_ga27gi738.s[46]++;
        $state.go('index.portfolio');
      } else {
        cov_ga27gi738.b[6][1]++;
        cov_ga27gi738.s[47]++;

        $scope.error_mfa = true;
        cov_ga27gi738.s[48]++;
        return;
      }
    });
  };

  cov_ga27gi738.s[49]++;
  $scope.mfaCheck = function () {
    cov_ga27gi738.f[10]++;
    cov_ga27gi738.s[50]++;

    $log.log($rootScope.connectedUser);
    cov_ga27gi738.s[51]++;
    if ((cov_ga27gi738.b[8][0]++, !$scope.mfa.code) || (cov_ga27gi738.b[8][1]++, $scope.mfa.code.length < 6)) {
      cov_ga27gi738.b[7][0]++;
      cov_ga27gi738.s[52]++;

      $scope.error_mfa = false;
      cov_ga27gi738.s[53]++;
      $scope.error_mfa_length = true;
      cov_ga27gi738.s[54]++;
      return;
    } else {
      cov_ga27gi738.b[7][1]++;
    }

    cov_ga27gi738.s[55]++;
    $CRUDService.save(PATH, { action: 'verify_qrcode', value: $scope.mfa.code }, function (response) {
      cov_ga27gi738.f[11]++;
      cov_ga27gi738.s[56]++;

      $log.log('code valid : ' + response);
      cov_ga27gi738.s[57]++;
      $scope.error_mfa_length = false;
      cov_ga27gi738.s[58]++;
      if (response.valid) {
        cov_ga27gi738.b[9][0]++;
        cov_ga27gi738.s[59]++;

        $state.go('index.portfolio');
      } else {
        cov_ga27gi738.b[9][1]++;
        cov_ga27gi738.s[60]++;

        $scope.error_mfa = true;
        cov_ga27gi738.s[61]++;
        return;
      }
    });
  };

  cov_ga27gi738.s[62]++;
  $scope.validateMfa = function () {
    cov_ga27gi738.f[12]++;
    cov_ga27gi738.s[63]++;

    $state.go('index.portfolio');
  };

  cov_ga27gi738.s[64]++;
  $scope.toggle = function (a, b, c, d) {
    cov_ga27gi738.f[13]++;
    cov_ga27gi738.s[65]++;

    $scope.isMultifactor = a;
    cov_ga27gi738.s[66]++;
    $scope.choiceMethode = b;
    cov_ga27gi738.s[67]++;
    $scope.isAppMultifactor = c;
    cov_ga27gi738.s[68]++;
    $scope.isMailMultifactor = d;
  };

  cov_ga27gi738.s[69]++;
  $scope.toggleInfo = function (event) {
    cov_ga27gi738.f[14]++;
    cov_ga27gi738.s[70]++;

    $scope.isInfoVisible = !$scope.isInfoVisible;
    cov_ga27gi738.s[71]++;
    event.stopPropagation();
  };

  cov_ga27gi738.s[72]++;
  var clickHandler = function clickHandler(event) {
    cov_ga27gi738.f[15]++;
    cov_ga27gi738.s[73]++;

    if (!$scope.isInfoVisible) {
        cov_ga27gi738.b[10][0]++;
        cov_ga27gi738.s[74]++;
        return;
      } else {
      cov_ga27gi738.b[10][1]++;
    }var infoElement = (cov_ga27gi738.s[75]++, document.querySelector('div[ng-if="isInfoVisible"]'));
    cov_ga27gi738.s[76]++;
    if ((cov_ga27gi738.b[12][0]++, infoElement) && (cov_ga27gi738.b[12][1]++, !infoElement.contains(event.target))) {
      cov_ga27gi738.b[11][0]++;
      cov_ga27gi738.s[77]++;

      $scope.$apply(function () {
        cov_ga27gi738.f[16]++;
        cov_ga27gi738.s[78]++;

        $scope.isInfoVisible = false;
      });
    } else {
      cov_ga27gi738.b[11][1]++;
    }
  };

  cov_ga27gi738.s[79]++;
  $document.on('click', clickHandler);

  // Nettoyage lorsque le scope est détruit (éviter les fuites de mémoire)
  cov_ga27gi738.s[80]++;
  $scope.$on('$destroy', function () {
    cov_ga27gi738.f[17]++;
    cov_ga27gi738.s[81]++;

    $document.off('click', clickHandler);
  });

  // Fin gestion MFA
  cov_ga27gi738.s[82]++;
  init();
});