'use strict';

var cov_11z8x1t1ks = function () {
  var path = '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/dashboard-combine-indicator.js',
      hash = '8349dcccbe4e3875a5e6f7abc76ac550862d327f',
      Function = function () {}.constructor,
      global = new Function('return this')(),
      gcv = '__coverage__',
      coverageData = {
    path: '/opt/tomcat/.jenkins/workspace/delta-bitbucket/client/src/app/directives/dashboard-combine-indicator.js',
    statementMap: {
      '0': {
        start: {
          line: 2,
          column: 0
        },
        end: {
          line: 945,
          column: 3
        }
      },
      '1': {
        start: {
          line: 3,
          column: 2
        },
        end: {
          line: 944,
          column: 4
        }
      },
      '2': {
        start: {
          line: 17,
          column: 19
        },
        end: {
          line: 17,
          column: 32
        }
      },
      '3': {
        start: {
          line: 18,
          column: 24
        },
        end: {
          line: 18,
          column: 40
        }
      },
      '4': {
        start: {
          line: 19,
          column: 26
        },
        end: {
          line: 19,
          column: 35
        }
      },
      '5': {
        start: {
          line: 20,
          column: 28
        },
        end: {
          line: 593,
          column: 7
        }
      },
      '6': {
        start: {
          line: 22,
          column: 21
        },
        end: {
          line: 29,
          column: 11
        }
      },
      '7': {
        start: {
          line: 30,
          column: 10
        },
        end: {
          line: 35,
          column: 11
        }
      },
      '8': {
        start: {
          line: 31,
          column: 12
        },
        end: {
          line: 33,
          column: 13
        }
      },
      '9': {
        start: {
          line: 32,
          column: 14
        },
        end: {
          line: 32,
          column: 142
        }
      },
      '10': {
        start: {
          line: 34,
          column: 12
        },
        end: {
          line: 34,
          column: 34
        }
      },
      '11': {
        start: {
          line: 36,
          column: 10
        },
        end: {
          line: 36,
          column: 20
        }
      },
      '12': {
        start: {
          line: 39,
          column: 10
        },
        end: {
          line: 41,
          column: 11
        }
      },
      '13': {
        start: {
          line: 40,
          column: 12
        },
        end: {
          line: 40,
          column: 46
        }
      },
      '14': {
        start: {
          line: 43,
          column: 10
        },
        end: {
          line: 45,
          column: 11
        }
      },
      '15': {
        start: {
          line: 44,
          column: 12
        },
        end: {
          line: 44,
          column: 41
        }
      },
      '16': {
        start: {
          line: 46,
          column: 10
        },
        end: {
          line: 87,
          column: 13
        }
      },
      '17': {
        start: {
          line: 54,
          column: 12
        },
        end: {
          line: 56,
          column: 13
        }
      },
      '18': {
        start: {
          line: 55,
          column: 14
        },
        end: {
          line: 55,
          column: 47
        }
      },
      '19': {
        start: {
          line: 58,
          column: 29
        },
        end: {
          line: 58,
          column: 31
        }
      },
      '20': {
        start: {
          line: 59,
          column: 12
        },
        end: {
          line: 84,
          column: 13
        }
      },
      '21': {
        start: {
          line: 60,
          column: 24
        },
        end: {
          line: 73,
          column: 15
        }
      },
      '22': {
        start: {
          line: 74,
          column: 14
        },
        end: {
          line: 77,
          column: 15
        }
      },
      '23': {
        start: {
          line: 75,
          column: 16
        },
        end: {
          line: 75,
          column: 74
        }
      },
      '24': {
        start: {
          line: 76,
          column: 16
        },
        end: {
          line: 76,
          column: 25
        }
      },
      '25': {
        start: {
          line: 78,
          column: 14
        },
        end: {
          line: 83,
          column: 15
        }
      },
      '26': {
        start: {
          line: 79,
          column: 16
        },
        end: {
          line: 82,
          column: 17
        }
      },
      '27': {
        start: {
          line: 80,
          column: 18
        },
        end: {
          line: 80,
          column: 73
        }
      },
      '28': {
        start: {
          line: 81,
          column: 18
        },
        end: {
          line: 81,
          column: 24
        }
      },
      '29': {
        start: {
          line: 86,
          column: 12
        },
        end: {
          line: 86,
          column: 38
        }
      },
      '30': {
        start: {
          line: 90,
          column: 10
        },
        end: {
          line: 118,
          column: 13
        }
      },
      '31': {
        start: {
          line: 99,
          column: 12
        },
        end: {
          line: 101,
          column: 13
        }
      },
      '32': {
        start: {
          line: 100,
          column: 14
        },
        end: {
          line: 100,
          column: 58
        }
      },
      '33': {
        start: {
          line: 103,
          column: 29
        },
        end: {
          line: 103,
          column: 31
        }
      },
      '34': {
        start: {
          line: 104,
          column: 12
        },
        end: {
          line: 115,
          column: 13
        }
      },
      '35': {
        start: {
          line: 105,
          column: 24
        },
        end: {
          line: 108,
          column: 15
        }
      },
      '36': {
        start: {
          line: 109,
          column: 14
        },
        end: {
          line: 114,
          column: 15
        }
      },
      '37': {
        start: {
          line: 110,
          column: 16
        },
        end: {
          line: 113,
          column: 17
        }
      },
      '38': {
        start: {
          line: 111,
          column: 18
        },
        end: {
          line: 111,
          column: 73
        }
      },
      '39': {
        start: {
          line: 112,
          column: 18
        },
        end: {
          line: 112,
          column: 24
        }
      },
      '40': {
        start: {
          line: 117,
          column: 12
        },
        end: {
          line: 117,
          column: 38
        }
      },
      '41': {
        start: {
          line: 122,
          column: 21
        },
        end: {
          line: 122,
          column: 46
        }
      },
      '42': {
        start: {
          line: 123,
          column: 27
        },
        end: {
          line: 123,
          column: 29
        }
      },
      '43': {
        start: {
          line: 124,
          column: 10
        },
        end: {
          line: 158,
          column: 11
        }
      },
      '44': {
        start: {
          line: 125,
          column: 12
        },
        end: {
          line: 157,
          column: 15
        }
      },
      '45': {
        start: {
          line: 134,
          column: 14
        },
        end: {
          line: 134,
          column: 21
        }
      },
      '46': {
        start: {
          line: 135,
          column: 14
        },
        end: {
          line: 153,
          column: 15
        }
      },
      '47': {
        start: {
          line: 136,
          column: 26
        },
        end: {
          line: 142,
          column: 17
        }
      },
      '48': {
        start: {
          line: 137,
          column: 18
        },
        end: {
          line: 137,
          column: 47
        }
      },
      '49': {
        start: {
          line: 143,
          column: 16
        },
        end: {
          line: 152,
          column: 17
        }
      },
      '50': {
        start: {
          line: 144,
          column: 18
        },
        end: {
          line: 151,
          column: 19
        }
      },
      '51': {
        start: {
          line: 145,
          column: 20
        },
        end: {
          line: 145,
          column: 53
        }
      },
      '52': {
        start: {
          line: 146,
          column: 20
        },
        end: {
          line: 149,
          column: 21
        }
      },
      '53': {
        start: {
          line: 147,
          column: 22
        },
        end: {
          line: 147,
          column: 40
        }
      },
      '54': {
        start: {
          line: 148,
          column: 22
        },
        end: {
          line: 148,
          column: 39
        }
      },
      '55': {
        start: {
          line: 150,
          column: 20
        },
        end: {
          line: 150,
          column: 26
        }
      },
      '56': {
        start: {
          line: 154,
          column: 14
        },
        end: {
          line: 156,
          column: 15
        }
      },
      '57': {
        start: {
          line: 155,
          column: 16
        },
        end: {
          line: 155,
          column: 53
        }
      },
      '58': {
        start: {
          line: 161,
          column: 10
        },
        end: {
          line: 163,
          column: 11
        }
      },
      '59': {
        start: {
          line: 162,
          column: 12
        },
        end: {
          line: 162,
          column: 52
        }
      },
      '60': {
        start: {
          line: 164,
          column: 21
        },
        end: {
          line: 164,
          column: 46
        }
      },
      '61': {
        start: {
          line: 165,
          column: 27
        },
        end: {
          line: 165,
          column: 29
        }
      },
      '62': {
        start: {
          line: 166,
          column: 10
        },
        end: {
          line: 218,
          column: 11
        }
      },
      '63': {
        start: {
          line: 167,
          column: 12
        },
        end: {
          line: 217,
          column: 15
        }
      },
      '64': {
        start: {
          line: 175,
          column: 14
        },
        end: {
          line: 175,
          column: 21
        }
      },
      '65': {
        start: {
          line: 176,
          column: 14
        },
        end: {
          line: 212,
          column: 15
        }
      },
      '66': {
        start: {
          line: 177,
          column: 26
        },
        end: {
          line: 193,
          column: 17
        }
      },
      '67': {
        start: {
          line: 178,
          column: 18
        },
        end: {
          line: 178,
          column: 47
        }
      },
      '68': {
        start: {
          line: 194,
          column: 16
        },
        end: {
          line: 201,
          column: 17
        }
      },
      '69': {
        start: {
          line: 195,
          column: 18
        },
        end: {
          line: 195,
          column: 54
        }
      },
      '70': {
        start: {
          line: 196,
          column: 18
        },
        end: {
          line: 199,
          column: 19
        }
      },
      '71': {
        start: {
          line: 197,
          column: 20
        },
        end: {
          line: 197,
          column: 38
        }
      },
      '72': {
        start: {
          line: 198,
          column: 20
        },
        end: {
          line: 198,
          column: 37
        }
      },
      '73': {
        start: {
          line: 200,
          column: 18
        },
        end: {
          line: 200,
          column: 27
        }
      },
      '74': {
        start: {
          line: 202,
          column: 16
        },
        end: {
          line: 211,
          column: 17
        }
      },
      '75': {
        start: {
          line: 203,
          column: 18
        },
        end: {
          line: 210,
          column: 19
        }
      },
      '76': {
        start: {
          line: 204,
          column: 20
        },
        end: {
          line: 204,
          column: 53
        }
      },
      '77': {
        start: {
          line: 205,
          column: 20
        },
        end: {
          line: 208,
          column: 21
        }
      },
      '78': {
        start: {
          line: 206,
          column: 22
        },
        end: {
          line: 206,
          column: 40
        }
      },
      '79': {
        start: {
          line: 207,
          column: 22
        },
        end: {
          line: 207,
          column: 39
        }
      },
      '80': {
        start: {
          line: 209,
          column: 20
        },
        end: {
          line: 209,
          column: 26
        }
      },
      '81': {
        start: {
          line: 214,
          column: 14
        },
        end: {
          line: 216,
          column: 15
        }
      },
      '82': {
        start: {
          line: 215,
          column: 16
        },
        end: {
          line: 215,
          column: 53
        }
      },
      '83': {
        start: {
          line: 221,
          column: 10
        },
        end: {
          line: 223,
          column: 11
        }
      },
      '84': {
        start: {
          line: 222,
          column: 12
        },
        end: {
          line: 222,
          column: 64
        }
      },
      '85': {
        start: {
          line: 224,
          column: 25
        },
        end: {
          line: 224,
          column: 27
        }
      },
      '86': {
        start: {
          line: 225,
          column: 10
        },
        end: {
          line: 241,
          column: 11
        }
      },
      '87': {
        start: {
          line: 226,
          column: 12
        },
        end: {
          line: 237,
          column: 15
        }
      },
      '88': {
        start: {
          line: 238,
          column: 12
        },
        end: {
          line: 240,
          column: 13
        }
      },
      '89': {
        start: {
          line: 239,
          column: 14
        },
        end: {
          line: 239,
          column: 67
        }
      },
      '90': {
        start: {
          line: 243,
          column: 10
        },
        end: {
          line: 279,
          column: 12
        }
      },
      '91': {
        start: {
          line: 283,
          column: 10
        },
        end: {
          line: 285,
          column: 11
        }
      },
      '92': {
        start: {
          line: 284,
          column: 12
        },
        end: {
          line: 284,
          column: 53
        }
      },
      '93': {
        start: {
          line: 286,
          column: 10
        },
        end: {
          line: 288,
          column: 11
        }
      },
      '94': {
        start: {
          line: 287,
          column: 12
        },
        end: {
          line: 287,
          column: 50
        }
      },
      '95': {
        start: {
          line: 289,
          column: 10
        },
        end: {
          line: 337,
          column: 12
        }
      },
      '96': {
        start: {
          line: 340,
          column: 10
        },
        end: {
          line: 402,
          column: 12
        }
      },
      '97': {
        start: {
          line: 405,
          column: 25
        },
        end: {
          line: 405,
          column: 27
        }
      },
      '98': {
        start: {
          line: 406,
          column: 10
        },
        end: {
          line: 437,
          column: 11
        }
      },
      '99': {
        start: {
          line: 407,
          column: 12
        },
        end: {
          line: 418,
          column: 15
        }
      },
      '100': {
        start: {
          line: 420,
          column: 12
        },
        end: {
          line: 431,
          column: 15
        }
      },
      '101': {
        start: {
          line: 433,
          column: 12
        },
        end: {
          line: 436,
          column: 13
        }
      },
      '102': {
        start: {
          line: 434,
          column: 14
        },
        end: {
          line: 434,
          column: 67
        }
      },
      '103': {
        start: {
          line: 435,
          column: 14
        },
        end: {
          line: 435,
          column: 67
        }
      },
      '104': {
        start: {
          line: 439,
          column: 10
        },
        end: {
          line: 475,
          column: 12
        }
      },
      '105': {
        start: {
          line: 478,
          column: 10
        },
        end: {
          line: 498,
          column: 12
        }
      },
      '106': {
        start: {
          line: 487,
          column: 14
        },
        end: {
          line: 489,
          column: 15
        }
      },
      '107': {
        start: {
          line: 488,
          column: 16
        },
        end: {
          line: 488,
          column: 43
        }
      },
      '108': {
        start: {
          line: 490,
          column: 14
        },
        end: {
          line: 490,
          column: 65
        }
      },
      '109': {
        start: {
          line: 501,
          column: 23
        },
        end: {
          line: 501,
          column: 25
        }
      },
      '110': {
        start: {
          line: 502,
          column: 10
        },
        end: {
          line: 534,
          column: 11
        }
      },
      '111': {
        start: {
          line: 503,
          column: 12
        },
        end: {
          line: 507,
          column: 15
        }
      },
      '112': {
        start: {
          line: 509,
          column: 31
        },
        end: {
          line: 513,
          column: 14
        }
      },
      '113': {
        start: {
          line: 510,
          column: 14
        },
        end: {
          line: 510,
          column: 52
        }
      },
      '114': {
        start: {
          line: 512,
          column: 14
        },
        end: {
          line: 512,
          column: 52
        }
      },
      '115': {
        start: {
          line: 514,
          column: 12
        },
        end: {
          line: 519,
          column: 13
        }
      },
      '116': {
        start: {
          line: 515,
          column: 14
        },
        end: {
          line: 518,
          column: 48
        }
      },
      '117': {
        start: {
          line: 520,
          column: 29
        },
        end: {
          line: 522,
          column: 14
        }
      },
      '118': {
        start: {
          line: 521,
          column: 14
        },
        end: {
          line: 521,
          column: 55
        }
      },
      '119': {
        start: {
          line: 523,
          column: 12
        },
        end: {
          line: 528,
          column: 13
        }
      },
      '120': {
        start: {
          line: 524,
          column: 14
        },
        end: {
          line: 527,
          column: 46
        }
      },
      '121': {
        start: {
          line: 530,
          column: 12
        },
        end: {
          line: 533,
          column: 46
        }
      },
      '122': {
        start: {
          line: 535,
          column: 27
        },
        end: {
          line: 535,
          column: 28
        }
      },
      '123': {
        start: {
          line: 536,
          column: 10
        },
        end: {
          line: 591,
          column: 12
        }
      },
      '124': {
        start: {
          line: 539,
          column: 14
        },
        end: {
          line: 542,
          column: 15
        }
      },
      '125': {
        start: {
          line: 540,
          column: 16
        },
        end: {
          line: 540,
          column: 40
        }
      },
      '126': {
        start: {
          line: 541,
          column: 16
        },
        end: {
          line: 541,
          column: 23
        }
      },
      '127': {
        start: {
          line: 543,
          column: 14
        },
        end: {
          line: 578,
          column: 15
        }
      },
      '128': {
        start: {
          line: 544,
          column: 16
        },
        end: {
          line: 577,
          column: 25
        }
      },
      '129': {
        start: {
          line: 545,
          column: 18
        },
        end: {
          line: 547,
          column: 19
        }
      },
      '130': {
        start: {
          line: 546,
          column: 20
        },
        end: {
          line: 546,
          column: 27
        }
      },
      '131': {
        start: {
          line: 549,
          column: 36
        },
        end: {
          line: 549,
          column: 99
        }
      },
      '132': {
        start: {
          line: 551,
          column: 38
        },
        end: {
          line: 551,
          column: 82
        }
      },
      '133': {
        start: {
          line: 553,
          column: 18
        },
        end: {
          line: 576,
          column: 42
        }
      },
      '134': {
        start: {
          line: 594,
          column: 29
        },
        end: {
          line: 937,
          column: 7
        }
      },
      '135': {
        start: {
          line: 596,
          column: 10
        },
        end: {
          line: 618,
          column: 11
        }
      },
      '136': {
        start: {
          line: 597,
          column: 12
        },
        end: {
          line: 599,
          column: 13
        }
      },
      '137': {
        start: {
          line: 598,
          column: 14
        },
        end: {
          line: 598,
          column: 23
        }
      },
      '138': {
        start: {
          line: 600,
          column: 12
        },
        end: {
          line: 600,
          column: 41
        }
      },
      '139': {
        start: {
          line: 601,
          column: 12
        },
        end: {
          line: 601,
          column: 79
        }
      },
      '140': {
        start: {
          line: 602,
          column: 12
        },
        end: {
          line: 602,
          column: 79
        }
      },
      '141': {
        start: {
          line: 612,
          column: 12
        },
        end: {
          line: 614,
          column: 14
        }
      },
      '142': {
        start: {
          line: 615,
          column: 12
        },
        end: {
          line: 617,
          column: 14
        }
      },
      '143': {
        start: {
          line: 620,
          column: 10
        },
        end: {
          line: 620,
          column: 65
        }
      },
      '144': {
        start: {
          line: 621,
          column: 10
        },
        end: {
          line: 621,
          column: 65
        }
      },
      '145': {
        start: {
          line: 623,
          column: 10
        },
        end: {
          line: 623,
          column: 166
        }
      },
      '146': {
        start: {
          line: 624,
          column: 10
        },
        end: {
          line: 624,
          column: 166
        }
      },
      '147': {
        start: {
          line: 626,
          column: 10
        },
        end: {
          line: 626,
          column: 62
        }
      },
      '148': {
        start: {
          line: 627,
          column: 10
        },
        end: {
          line: 627,
          column: 21
        }
      },
      '149': {
        start: {
          line: 630,
          column: 10
        },
        end: {
          line: 653,
          column: 13
        }
      },
      '150': {
        start: {
          line: 631,
          column: 12
        },
        end: {
          line: 631,
          column: 44
        }
      },
      '151': {
        start: {
          line: 633,
          column: 12
        },
        end: {
          line: 652,
          column: 15
        }
      },
      '152': {
        start: {
          line: 642,
          column: 32
        },
        end: {
          line: 642,
          column: 34
        }
      },
      '153': {
        start: {
          line: 643,
          column: 14
        },
        end: {
          line: 645,
          column: 15
        }
      },
      '154': {
        start: {
          line: 644,
          column: 16
        },
        end: {
          line: 644,
          column: 55
        }
      },
      '155': {
        start: {
          line: 647,
          column: 14
        },
        end: {
          line: 649,
          column: 15
        }
      },
      '156': {
        start: {
          line: 648,
          column: 16
        },
        end: {
          line: 648,
          column: 53
        }
      },
      '157': {
        start: {
          line: 651,
          column: 14
        },
        end: {
          line: 651,
          column: 41
        }
      },
      '158': {
        start: {
          line: 656,
          column: 29
        },
        end: {
          line: 656,
          column: 31
        }
      },
      '159': {
        start: {
          line: 657,
          column: 26
        },
        end: {
          line: 659,
          column: 126
        }
      },
      '160': {
        start: {
          line: 662,
          column: 22
        },
        end: {
          line: 662,
          column: 23
        }
      },
      '161': {
        start: {
          line: 663,
          column: 10
        },
        end: {
          line: 684,
          column: 11
        }
      },
      '162': {
        start: {
          line: 664,
          column: 12
        },
        end: {
          line: 666,
          column: 13
        }
      },
      '163': {
        start: {
          line: 665,
          column: 14
        },
        end: {
          line: 665,
          column: 23
        }
      },
      '164': {
        start: {
          line: 667,
          column: 12
        },
        end: {
          line: 670,
          column: 15
        }
      },
      '165': {
        start: {
          line: 671,
          column: 12
        },
        end: {
          line: 681,
          column: 13
        }
      },
      '166': {
        start: {
          line: 672,
          column: 26
        },
        end: {
          line: 672,
          column: 72
        }
      },
      '167': {
        start: {
          line: 673,
          column: 14
        },
        end: {
          line: 680,
          column: 15
        }
      },
      '168': {
        start: {
          line: 674,
          column: 16
        },
        end: {
          line: 678,
          column: 19
        }
      },
      '169': {
        start: {
          line: 679,
          column: 16
        },
        end: {
          line: 679,
          column: 24
        }
      },
      '170': {
        start: {
          line: 682,
          column: 12
        },
        end: {
          line: 682,
          column: 51
        }
      },
      '171': {
        start: {
          line: 683,
          column: 12
        },
        end: {
          line: 683,
          column: 18
        }
      },
      '172': {
        start: {
          line: 686,
          column: 10
        },
        end: {
          line: 758,
          column: 12
        }
      },
      '173': {
        start: {
          line: 762,
          column: 26
        },
        end: {
          line: 764,
          column: 126
        }
      },
      '174': {
        start: {
          line: 766,
          column: 26
        },
        end: {
          line: 771,
          column: 12
        }
      },
      '175': {
        start: {
          line: 772,
          column: 10
        },
        end: {
          line: 781,
          column: 11
        }
      },
      '176': {
        start: {
          line: 773,
          column: 24
        },
        end: {
          line: 773,
          column: 70
        }
      },
      '177': {
        start: {
          line: 774,
          column: 12
        },
        end: {
          line: 780,
          column: 15
        }
      },
      '178': {
        start: {
          line: 783,
          column: 10
        },
        end: {
          line: 786,
          column: 13
        }
      },
      '179': {
        start: {
          line: 788,
          column: 10
        },
        end: {
          line: 792,
          column: 13
        }
      },
      '180': {
        start: {
          line: 794,
          column: 10
        },
        end: {
          line: 797,
          column: 13
        }
      },
      '181': {
        start: {
          line: 799,
          column: 10
        },
        end: {
          line: 851,
          column: 12
        }
      },
      '182': {
        start: {
          line: 806,
          column: 14
        },
        end: {
          line: 843,
          column: 15
        }
      },
      '183': {
        start: {
          line: 809,
          column: 16
        },
        end: {
          line: 811,
          column: 17
        }
      },
      '184': {
        start: {
          line: 810,
          column: 18
        },
        end: {
          line: 810,
          column: 25
        }
      },
      '185': {
        start: {
          line: 812,
          column: 29
        },
        end: {
          line: 812,
          column: 93
        }
      },
      '186': {
        start: {
          line: 813,
          column: 34
        },
        end: {
          line: 813,
          column: 89
        }
      },
      '187': {
        start: {
          line: 815,
          column: 36
        },
        end: {
          line: 815,
          column: 72
        }
      },
      '188': {
        start: {
          line: 817,
          column: 16
        },
        end: {
          line: 842,
          column: 25
        }
      },
      '189': {
        start: {
          line: 818,
          column: 18
        },
        end: {
          line: 841,
          column: 51
        }
      },
      '190': {
        start: {
          line: 938,
          column: 6
        },
        end: {
          line: 942,
          column: 7
        }
      },
      '191': {
        start: {
          line: 939,
          column: 8
        },
        end: {
          line: 939,
          column: 33
        }
      },
      '192': {
        start: {
          line: 941,
          column: 8
        },
        end: {
          line: 941,
          column: 32
        }
      }
    },
    fnMap: {
      '0': {
        name: '(anonymous_0)',
        decl: {
          start: {
            line: 2,
            column: 61
          },
          end: {
            line: 2,
            column: 62
          }
        },
        loc: {
          start: {
            line: 2,
            column: 73
          },
          end: {
            line: 945,
            column: 1
          }
        },
        line: 2
      },
      '1': {
        name: '(anonymous_1)',
        decl: {
          start: {
            line: 53,
            column: 13
          },
          end: {
            line: 53,
            column: 14
          }
        },
        loc: {
          start: {
            line: 53,
            column: 21
          },
          end: {
            line: 87,
            column: 11
          }
        },
        line: 53
      },
      '2': {
        name: '(anonymous_2)',
        decl: {
          start: {
            line: 98,
            column: 13
          },
          end: {
            line: 98,
            column: 14
          }
        },
        loc: {
          start: {
            line: 98,
            column: 21
          },
          end: {
            line: 118,
            column: 11
          }
        },
        line: 98
      },
      '3': {
        name: '(anonymous_3)',
        decl: {
          start: {
            line: 133,
            column: 15
          },
          end: {
            line: 133,
            column: 16
          }
        },
        loc: {
          start: {
            line: 133,
            column: 23
          },
          end: {
            line: 157,
            column: 13
          }
        },
        line: 133
      },
      '4': {
        name: '(anonymous_4)',
        decl: {
          start: {
            line: 136,
            column: 40
          },
          end: {
            line: 136,
            column: 41
          }
        },
        loc: {
          start: {
            line: 136,
            column: 49
          },
          end: {
            line: 138,
            column: 17
          }
        },
        line: 136
      },
      '5': {
        name: '(anonymous_5)',
        decl: {
          start: {
            line: 174,
            column: 15
          },
          end: {
            line: 174,
            column: 16
          }
        },
        loc: {
          start: {
            line: 174,
            column: 23
          },
          end: {
            line: 217,
            column: 13
          }
        },
        line: 174
      },
      '6': {
        name: '(anonymous_6)',
        decl: {
          start: {
            line: 177,
            column: 40
          },
          end: {
            line: 177,
            column: 41
          }
        },
        loc: {
          start: {
            line: 177,
            column: 49
          },
          end: {
            line: 179,
            column: 17
          }
        },
        line: 177
      },
      '7': {
        name: '(anonymous_7)',
        decl: {
          start: {
            line: 509,
            column: 48
          },
          end: {
            line: 509,
            column: 49
          }
        },
        loc: {
          start: {
            line: 509,
            column: 62
          },
          end: {
            line: 511,
            column: 13
          }
        },
        line: 509
      },
      '8': {
        name: '(anonymous_8)',
        decl: {
          start: {
            line: 511,
            column: 34
          },
          end: {
            line: 511,
            column: 35
          }
        },
        loc: {
          start: {
            line: 511,
            column: 41
          },
          end: {
            line: 513,
            column: 13
          }
        },
        line: 511
      },
      '9': {
        name: '(anonymous_9)',
        decl: {
          start: {
            line: 520,
            column: 48
          },
          end: {
            line: 520,
            column: 49
          }
        },
        loc: {
          start: {
            line: 520,
            column: 60
          },
          end: {
            line: 522,
            column: 13
          }
        },
        line: 520
      },
      '10': {
        name: '(anonymous_10)',
        decl: {
          start: {
            line: 544,
            column: 25
          },
          end: {
            line: 544,
            column: 26
          }
        },
        loc: {
          start: {
            line: 544,
            column: 31
          },
          end: {
            line: 577,
            column: 17
          }
        },
        line: 544
      },
      '11': {
        name: '(anonymous_11)',
        decl: {
          start: {
            line: 630,
            column: 99
          },
          end: {
            line: 630,
            column: 100
          }
        },
        loc: {
          start: {
            line: 630,
            column: 112
          },
          end: {
            line: 653,
            column: 11
          }
        },
        line: 630
      },
      '12': {
        name: '(anonymous_12)',
        decl: {
          start: {
            line: 641,
            column: 15
          },
          end: {
            line: 641,
            column: 16
          }
        },
        loc: {
          start: {
            line: 641,
            column: 23
          },
          end: {
            line: 652,
            column: 13
          }
        },
        line: 641
      },
      '13': {
        name: '(anonymous_13)',
        decl: {
          start: {
            line: 817,
            column: 25
          },
          end: {
            line: 817,
            column: 26
          }
        },
        loc: {
          start: {
            line: 817,
            column: 31
          },
          end: {
            line: 842,
            column: 17
          }
        },
        line: 817
      }
    },
    branchMap: {
      '0': {
        loc: {
          start: {
            line: 30,
            column: 10
          },
          end: {
            line: 35,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 30,
            column: 10
          },
          end: {
            line: 35,
            column: 11
          }
        }, {
          start: {
            line: 30,
            column: 10
          },
          end: {
            line: 35,
            column: 11
          }
        }],
        line: 30
      },
      '1': {
        loc: {
          start: {
            line: 30,
            column: 16
          },
          end: {
            line: 30,
            column: 72
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 30,
            column: 16
          },
          end: {
            line: 30,
            column: 42
          }
        }, {
          start: {
            line: 30,
            column: 46
          },
          end: {
            line: 30,
            column: 72
          }
        }],
        line: 30
      },
      '2': {
        loc: {
          start: {
            line: 31,
            column: 12
          },
          end: {
            line: 33,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 31,
            column: 12
          },
          end: {
            line: 33,
            column: 13
          }
        }, {
          start: {
            line: 31,
            column: 12
          },
          end: {
            line: 33,
            column: 13
          }
        }],
        line: 31
      },
      '3': {
        loc: {
          start: {
            line: 32,
            column: 49
          },
          end: {
            line: 32,
            column: 83
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 32,
            column: 76
          },
          end: {
            line: 32,
            column: 79
          }
        }, {
          start: {
            line: 32,
            column: 82
          },
          end: {
            line: 32,
            column: 83
          }
        }],
        line: 32
      },
      '4': {
        loc: {
          start: {
            line: 32,
            column: 108
          },
          end: {
            line: 32,
            column: 140
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 32,
            column: 135
          },
          end: {
            line: 32,
            column: 136
          }
        }, {
          start: {
            line: 32,
            column: 139
          },
          end: {
            line: 32,
            column: 140
          }
        }],
        line: 32
      },
      '5': {
        loc: {
          start: {
            line: 39,
            column: 10
          },
          end: {
            line: 41,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 39,
            column: 10
          },
          end: {
            line: 41,
            column: 11
          }
        }, {
          start: {
            line: 39,
            column: 10
          },
          end: {
            line: 41,
            column: 11
          }
        }],
        line: 39
      },
      '6': {
        loc: {
          start: {
            line: 39,
            column: 14
          },
          end: {
            line: 39,
            column: 71
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 39,
            column: 14
          },
          end: {
            line: 39,
            column: 38
          }
        }, {
          start: {
            line: 39,
            column: 42
          },
          end: {
            line: 39,
            column: 71
          }
        }],
        line: 39
      },
      '7': {
        loc: {
          start: {
            line: 43,
            column: 10
          },
          end: {
            line: 45,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 43,
            column: 10
          },
          end: {
            line: 45,
            column: 11
          }
        }, {
          start: {
            line: 43,
            column: 10
          },
          end: {
            line: 45,
            column: 11
          }
        }],
        line: 43
      },
      '8': {
        loc: {
          start: {
            line: 54,
            column: 12
          },
          end: {
            line: 56,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 54,
            column: 12
          },
          end: {
            line: 56,
            column: 13
          }
        }, {
          start: {
            line: 54,
            column: 12
          },
          end: {
            line: 56,
            column: 13
          }
        }],
        line: 54
      },
      '9': {
        loc: {
          start: {
            line: 74,
            column: 14
          },
          end: {
            line: 77,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 74,
            column: 14
          },
          end: {
            line: 77,
            column: 15
          }
        }, {
          start: {
            line: 74,
            column: 14
          },
          end: {
            line: 77,
            column: 15
          }
        }],
        line: 74
      },
      '10': {
        loc: {
          start: {
            line: 79,
            column: 16
          },
          end: {
            line: 82,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 79,
            column: 16
          },
          end: {
            line: 82,
            column: 17
          }
        }, {
          start: {
            line: 79,
            column: 16
          },
          end: {
            line: 82,
            column: 17
          }
        }],
        line: 79
      },
      '11': {
        loc: {
          start: {
            line: 89,
            column: 23
          },
          end: {
            line: 89,
            column: 38
          }
        },
        type: 'default-arg',
        locations: [{
          start: {
            line: 89,
            column: 36
          },
          end: {
            line: 89,
            column: 38
          }
        }],
        line: 89
      },
      '12': {
        loc: {
          start: {
            line: 99,
            column: 12
          },
          end: {
            line: 101,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 99,
            column: 12
          },
          end: {
            line: 101,
            column: 13
          }
        }, {
          start: {
            line: 99,
            column: 12
          },
          end: {
            line: 101,
            column: 13
          }
        }],
        line: 99
      },
      '13': {
        loc: {
          start: {
            line: 110,
            column: 16
          },
          end: {
            line: 113,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 110,
            column: 16
          },
          end: {
            line: 113,
            column: 17
          }
        }, {
          start: {
            line: 110,
            column: 16
          },
          end: {
            line: 113,
            column: 17
          }
        }],
        line: 110
      },
      '14': {
        loc: {
          start: {
            line: 136,
            column: 26
          },
          end: {
            line: 142,
            column: 17
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 136,
            column: 26
          },
          end: {
            line: 138,
            column: 18
          }
        }, {
          start: {
            line: 138,
            column: 22
          },
          end: {
            line: 142,
            column: 17
          }
        }],
        line: 136
      },
      '15': {
        loc: {
          start: {
            line: 144,
            column: 18
          },
          end: {
            line: 151,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 144,
            column: 18
          },
          end: {
            line: 151,
            column: 19
          }
        }, {
          start: {
            line: 144,
            column: 18
          },
          end: {
            line: 151,
            column: 19
          }
        }],
        line: 144
      },
      '16': {
        loc: {
          start: {
            line: 146,
            column: 20
          },
          end: {
            line: 149,
            column: 21
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 146,
            column: 20
          },
          end: {
            line: 149,
            column: 21
          }
        }, {
          start: {
            line: 146,
            column: 20
          },
          end: {
            line: 149,
            column: 21
          }
        }],
        line: 146
      },
      '17': {
        loc: {
          start: {
            line: 154,
            column: 14
          },
          end: {
            line: 156,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 154,
            column: 14
          },
          end: {
            line: 156,
            column: 15
          }
        }, {
          start: {
            line: 154,
            column: 14
          },
          end: {
            line: 156,
            column: 15
          }
        }],
        line: 154
      },
      '18': {
        loc: {
          start: {
            line: 161,
            column: 10
          },
          end: {
            line: 163,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 161,
            column: 10
          },
          end: {
            line: 163,
            column: 11
          }
        }, {
          start: {
            line: 161,
            column: 10
          },
          end: {
            line: 163,
            column: 11
          }
        }],
        line: 161
      },
      '19': {
        loc: {
          start: {
            line: 177,
            column: 26
          },
          end: {
            line: 193,
            column: 17
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 177,
            column: 26
          },
          end: {
            line: 179,
            column: 18
          }
        }, {
          start: {
            line: 179,
            column: 22
          },
          end: {
            line: 193,
            column: 17
          }
        }],
        line: 177
      },
      '20': {
        loc: {
          start: {
            line: 194,
            column: 16
          },
          end: {
            line: 201,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 194,
            column: 16
          },
          end: {
            line: 201,
            column: 17
          }
        }, {
          start: {
            line: 194,
            column: 16
          },
          end: {
            line: 201,
            column: 17
          }
        }],
        line: 194
      },
      '21': {
        loc: {
          start: {
            line: 196,
            column: 18
          },
          end: {
            line: 199,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 196,
            column: 18
          },
          end: {
            line: 199,
            column: 19
          }
        }, {
          start: {
            line: 196,
            column: 18
          },
          end: {
            line: 199,
            column: 19
          }
        }],
        line: 196
      },
      '22': {
        loc: {
          start: {
            line: 203,
            column: 18
          },
          end: {
            line: 210,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 203,
            column: 18
          },
          end: {
            line: 210,
            column: 19
          }
        }, {
          start: {
            line: 203,
            column: 18
          },
          end: {
            line: 210,
            column: 19
          }
        }],
        line: 203
      },
      '23': {
        loc: {
          start: {
            line: 205,
            column: 20
          },
          end: {
            line: 208,
            column: 21
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 205,
            column: 20
          },
          end: {
            line: 208,
            column: 21
          }
        }, {
          start: {
            line: 205,
            column: 20
          },
          end: {
            line: 208,
            column: 21
          }
        }],
        line: 205
      },
      '24': {
        loc: {
          start: {
            line: 214,
            column: 14
          },
          end: {
            line: 216,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 214,
            column: 14
          },
          end: {
            line: 216,
            column: 15
          }
        }, {
          start: {
            line: 214,
            column: 14
          },
          end: {
            line: 216,
            column: 15
          }
        }],
        line: 214
      },
      '25': {
        loc: {
          start: {
            line: 221,
            column: 10
          },
          end: {
            line: 223,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 221,
            column: 10
          },
          end: {
            line: 223,
            column: 11
          }
        }, {
          start: {
            line: 221,
            column: 10
          },
          end: {
            line: 223,
            column: 11
          }
        }],
        line: 221
      },
      '26': {
        loc: {
          start: {
            line: 227,
            column: 20
          },
          end: {
            line: 227,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 227,
            column: 46
          },
          end: {
            line: 227,
            column: 54
          }
        }, {
          start: {
            line: 227,
            column: 57
          },
          end: {
            line: 227,
            column: 63
          }
        }],
        line: 227
      },
      '27': {
        loc: {
          start: {
            line: 228,
            column: 23
          },
          end: {
            line: 228,
            column: 56
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 228,
            column: 49
          },
          end: {
            line: 228,
            column: 50
          }
        }, {
          start: {
            line: 228,
            column: 53
          },
          end: {
            line: 228,
            column: 56
          }
        }],
        line: 228
      },
      '28': {
        loc: {
          start: {
            line: 232,
            column: 22
          },
          end: {
            line: 236,
            column: 15
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 232,
            column: 48
          },
          end: {
            line: 232,
            column: 57
          }
        }, {
          start: {
            line: 232,
            column: 60
          },
          end: {
            line: 236,
            column: 15
          }
        }],
        line: 232
      },
      '29': {
        loc: {
          start: {
            line: 239,
            column: 37
          },
          end: {
            line: 239,
            column: 62
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 239,
            column: 37
          },
          end: {
            line: 239,
            column: 48
          }
        }, {
          start: {
            line: 239,
            column: 52
          },
          end: {
            line: 239,
            column: 62
          }
        }],
        line: 239
      },
      '30': {
        loc: {
          start: {
            line: 273,
            column: 20
          },
          end: {
            line: 273,
            column: 43
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 273,
            column: 20
          },
          end: {
            line: 273,
            column: 33
          }
        }, {
          start: {
            line: 273,
            column: 37
          },
          end: {
            line: 273,
            column: 43
          }
        }],
        line: 273
      },
      '31': {
        loc: {
          start: {
            line: 283,
            column: 10
          },
          end: {
            line: 285,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 283,
            column: 10
          },
          end: {
            line: 285,
            column: 11
          }
        }, {
          start: {
            line: 283,
            column: 10
          },
          end: {
            line: 285,
            column: 11
          }
        }],
        line: 283
      },
      '32': {
        loc: {
          start: {
            line: 286,
            column: 10
          },
          end: {
            line: 288,
            column: 11
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 286,
            column: 10
          },
          end: {
            line: 288,
            column: 11
          }
        }, {
          start: {
            line: 286,
            column: 10
          },
          end: {
            line: 288,
            column: 11
          }
        }],
        line: 286
      },
      '33': {
        loc: {
          start: {
            line: 316,
            column: 20
          },
          end: {
            line: 316,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 316,
            column: 46
          },
          end: {
            line: 316,
            column: 54
          }
        }, {
          start: {
            line: 316,
            column: 57
          },
          end: {
            line: 316,
            column: 63
          }
        }],
        line: 316
      },
      '34': {
        loc: {
          start: {
            line: 317,
            column: 23
          },
          end: {
            line: 317,
            column: 56
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 317,
            column: 49
          },
          end: {
            line: 317,
            column: 50
          }
        }, {
          start: {
            line: 317,
            column: 53
          },
          end: {
            line: 317,
            column: 56
          }
        }],
        line: 317
      },
      '35': {
        loc: {
          start: {
            line: 322,
            column: 22
          },
          end: {
            line: 326,
            column: 15
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 322,
            column: 48
          },
          end: {
            line: 322,
            column: 57
          }
        }, {
          start: {
            line: 322,
            column: 60
          },
          end: {
            line: 326,
            column: 15
          }
        }],
        line: 322
      },
      '36': {
        loc: {
          start: {
            line: 331,
            column: 20
          },
          end: {
            line: 331,
            column: 43
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 331,
            column: 20
          },
          end: {
            line: 331,
            column: 33
          }
        }, {
          start: {
            line: 331,
            column: 37
          },
          end: {
            line: 331,
            column: 43
          }
        }],
        line: 331
      },
      '37': {
        loc: {
          start: {
            line: 367,
            column: 20
          },
          end: {
            line: 367,
            column: 103
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 367,
            column: 53
          },
          end: {
            line: 367,
            column: 61
          }
        }, {
          start: {
            line: 367,
            column: 65
          },
          end: {
            line: 367,
            column: 102
          }
        }],
        line: 367
      },
      '38': {
        loc: {
          start: {
            line: 367,
            column: 65
          },
          end: {
            line: 367,
            column: 102
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 367,
            column: 87
          },
          end: {
            line: 367,
            column: 93
          }
        }, {
          start: {
            line: 367,
            column: 96
          },
          end: {
            line: 367,
            column: 102
          }
        }],
        line: 367
      },
      '39': {
        loc: {
          start: {
            line: 368,
            column: 23
          },
          end: {
            line: 368,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 368,
            column: 56
          },
          end: {
            line: 368,
            column: 57
          }
        }, {
          start: {
            line: 368,
            column: 60
          },
          end: {
            line: 368,
            column: 63
          }
        }],
        line: 368
      },
      '40': {
        loc: {
          start: {
            line: 374,
            column: 22
          },
          end: {
            line: 378,
            column: 15
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 374,
            column: 55
          },
          end: {
            line: 374,
            column: 64
          }
        }, {
          start: {
            line: 374,
            column: 67
          },
          end: {
            line: 378,
            column: 15
          }
        }],
        line: 374
      },
      '41': {
        loc: {
          start: {
            line: 380,
            column: 20
          },
          end: {
            line: 380,
            column: 103
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 380,
            column: 53
          },
          end: {
            line: 380,
            column: 61
          }
        }, {
          start: {
            line: 380,
            column: 65
          },
          end: {
            line: 380,
            column: 102
          }
        }],
        line: 380
      },
      '42': {
        loc: {
          start: {
            line: 380,
            column: 65
          },
          end: {
            line: 380,
            column: 102
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 380,
            column: 87
          },
          end: {
            line: 380,
            column: 93
          }
        }, {
          start: {
            line: 380,
            column: 96
          },
          end: {
            line: 380,
            column: 102
          }
        }],
        line: 380
      },
      '43': {
        loc: {
          start: {
            line: 381,
            column: 23
          },
          end: {
            line: 381,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 381,
            column: 56
          },
          end: {
            line: 381,
            column: 57
          }
        }, {
          start: {
            line: 381,
            column: 60
          },
          end: {
            line: 381,
            column: 63
          }
        }],
        line: 381
      },
      '44': {
        loc: {
          start: {
            line: 387,
            column: 22
          },
          end: {
            line: 391,
            column: 15
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 387,
            column: 55
          },
          end: {
            line: 387,
            column: 64
          }
        }, {
          start: {
            line: 387,
            column: 67
          },
          end: {
            line: 391,
            column: 15
          }
        }],
        line: 387
      },
      '45': {
        loc: {
          start: {
            line: 396,
            column: 20
          },
          end: {
            line: 396,
            column: 43
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 396,
            column: 20
          },
          end: {
            line: 396,
            column: 33
          }
        }, {
          start: {
            line: 396,
            column: 37
          },
          end: {
            line: 396,
            column: 43
          }
        }],
        line: 396
      },
      '46': {
        loc: {
          start: {
            line: 408,
            column: 20
          },
          end: {
            line: 408,
            column: 103
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 408,
            column: 53
          },
          end: {
            line: 408,
            column: 61
          }
        }, {
          start: {
            line: 408,
            column: 65
          },
          end: {
            line: 408,
            column: 102
          }
        }],
        line: 408
      },
      '47': {
        loc: {
          start: {
            line: 408,
            column: 65
          },
          end: {
            line: 408,
            column: 102
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 408,
            column: 87
          },
          end: {
            line: 408,
            column: 93
          }
        }, {
          start: {
            line: 408,
            column: 96
          },
          end: {
            line: 408,
            column: 102
          }
        }],
        line: 408
      },
      '48': {
        loc: {
          start: {
            line: 409,
            column: 23
          },
          end: {
            line: 409,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 409,
            column: 56
          },
          end: {
            line: 409,
            column: 57
          }
        }, {
          start: {
            line: 409,
            column: 60
          },
          end: {
            line: 409,
            column: 63
          }
        }],
        line: 409
      },
      '49': {
        loc: {
          start: {
            line: 413,
            column: 22
          },
          end: {
            line: 417,
            column: 15
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 413,
            column: 55
          },
          end: {
            line: 413,
            column: 64
          }
        }, {
          start: {
            line: 413,
            column: 67
          },
          end: {
            line: 417,
            column: 15
          }
        }],
        line: 413
      },
      '50': {
        loc: {
          start: {
            line: 421,
            column: 20
          },
          end: {
            line: 421,
            column: 103
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 421,
            column: 53
          },
          end: {
            line: 421,
            column: 61
          }
        }, {
          start: {
            line: 421,
            column: 65
          },
          end: {
            line: 421,
            column: 102
          }
        }],
        line: 421
      },
      '51': {
        loc: {
          start: {
            line: 421,
            column: 65
          },
          end: {
            line: 421,
            column: 102
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 421,
            column: 87
          },
          end: {
            line: 421,
            column: 93
          }
        }, {
          start: {
            line: 421,
            column: 96
          },
          end: {
            line: 421,
            column: 102
          }
        }],
        line: 421
      },
      '52': {
        loc: {
          start: {
            line: 422,
            column: 23
          },
          end: {
            line: 422,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 422,
            column: 56
          },
          end: {
            line: 422,
            column: 57
          }
        }, {
          start: {
            line: 422,
            column: 60
          },
          end: {
            line: 422,
            column: 63
          }
        }],
        line: 422
      },
      '53': {
        loc: {
          start: {
            line: 426,
            column: 22
          },
          end: {
            line: 430,
            column: 15
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 426,
            column: 55
          },
          end: {
            line: 426,
            column: 64
          }
        }, {
          start: {
            line: 426,
            column: 67
          },
          end: {
            line: 430,
            column: 15
          }
        }],
        line: 426
      },
      '54': {
        loc: {
          start: {
            line: 434,
            column: 37
          },
          end: {
            line: 434,
            column: 62
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 434,
            column: 37
          },
          end: {
            line: 434,
            column: 48
          }
        }, {
          start: {
            line: 434,
            column: 52
          },
          end: {
            line: 434,
            column: 62
          }
        }],
        line: 434
      },
      '55': {
        loc: {
          start: {
            line: 435,
            column: 37
          },
          end: {
            line: 435,
            column: 62
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 435,
            column: 37
          },
          end: {
            line: 435,
            column: 48
          }
        }, {
          start: {
            line: 435,
            column: 52
          },
          end: {
            line: 435,
            column: 62
          }
        }],
        line: 435
      },
      '56': {
        loc: {
          start: {
            line: 469,
            column: 20
          },
          end: {
            line: 469,
            column: 43
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 469,
            column: 20
          },
          end: {
            line: 469,
            column: 33
          }
        }, {
          start: {
            line: 469,
            column: 37
          },
          end: {
            line: 469,
            column: 43
          }
        }],
        line: 469
      },
      '57': {
        loc: {
          start: {
            line: 487,
            column: 14
          },
          end: {
            line: 489,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 487,
            column: 14
          },
          end: {
            line: 489,
            column: 15
          }
        }, {
          start: {
            line: 487,
            column: 14
          },
          end: {
            line: 489,
            column: 15
          }
        }],
        line: 487
      },
      '58': {
        loc: {
          start: {
            line: 496,
            column: 20
          },
          end: {
            line: 496,
            column: 43
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 496,
            column: 20
          },
          end: {
            line: 496,
            column: 33
          }
        }, {
          start: {
            line: 496,
            column: 37
          },
          end: {
            line: 496,
            column: 43
          }
        }],
        line: 496
      },
      '59': {
        loc: {
          start: {
            line: 509,
            column: 32
          },
          end: {
            line: 511,
            column: 25
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 509,
            column: 32
          },
          end: {
            line: 511,
            column: 14
          }
        }, {
          start: {
            line: 511,
            column: 18
          },
          end: {
            line: 511,
            column: 25
          }
        }],
        line: 509
      },
      '60': {
        loc: {
          start: {
            line: 514,
            column: 12
          },
          end: {
            line: 519,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 514,
            column: 12
          },
          end: {
            line: 519,
            column: 13
          }
        }, {
          start: {
            line: 514,
            column: 12
          },
          end: {
            line: 519,
            column: 13
          }
        }],
        line: 514
      },
      '61': {
        loc: {
          start: {
            line: 523,
            column: 12
          },
          end: {
            line: 528,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 523,
            column: 12
          },
          end: {
            line: 528,
            column: 13
          }
        }, {
          start: {
            line: 523,
            column: 12
          },
          end: {
            line: 528,
            column: 13
          }
        }],
        line: 523
      },
      '62': {
        loc: {
          start: {
            line: 539,
            column: 14
          },
          end: {
            line: 542,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 539,
            column: 14
          },
          end: {
            line: 542,
            column: 15
          }
        }, {
          start: {
            line: 539,
            column: 14
          },
          end: {
            line: 542,
            column: 15
          }
        }],
        line: 539
      },
      '63': {
        loc: {
          start: {
            line: 543,
            column: 14
          },
          end: {
            line: 578,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 543,
            column: 14
          },
          end: {
            line: 578,
            column: 15
          }
        }, {
          start: {
            line: 543,
            column: 14
          },
          end: {
            line: 578,
            column: 15
          }
        }],
        line: 543
      },
      '64': {
        loc: {
          start: {
            line: 545,
            column: 18
          },
          end: {
            line: 547,
            column: 19
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 545,
            column: 18
          },
          end: {
            line: 547,
            column: 19
          }
        }, {
          start: {
            line: 545,
            column: 18
          },
          end: {
            line: 547,
            column: 19
          }
        }],
        line: 545
      },
      '65': {
        loc: {
          start: {
            line: 597,
            column: 12
          },
          end: {
            line: 599,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 597,
            column: 12
          },
          end: {
            line: 599,
            column: 13
          }
        }, {
          start: {
            line: 597,
            column: 12
          },
          end: {
            line: 599,
            column: 13
          }
        }],
        line: 597
      },
      '66': {
        loc: {
          start: {
            line: 626,
            column: 19
          },
          end: {
            line: 626,
            column: 61
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 626,
            column: 34
          },
          end: {
            line: 626,
            column: 38
          }
        }, {
          start: {
            line: 626,
            column: 41
          },
          end: {
            line: 626,
            column: 61
          }
        }],
        line: 626
      },
      '67': {
        loc: {
          start: {
            line: 634,
            column: 19
          },
          end: {
            line: 634,
            column: 87
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 634,
            column: 47
          },
          end: {
            line: 634,
            column: 62
          }
        }, {
          start: {
            line: 634,
            column: 65
          },
          end: {
            line: 634,
            column: 87
          }
        }],
        line: 634
      },
      '68': {
        loc: {
          start: {
            line: 647,
            column: 14
          },
          end: {
            line: 649,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 647,
            column: 14
          },
          end: {
            line: 649,
            column: 15
          }
        }, {
          start: {
            line: 647,
            column: 14
          },
          end: {
            line: 649,
            column: 15
          }
        }],
        line: 647
      },
      '69': {
        loc: {
          start: {
            line: 657,
            column: 26
          },
          end: {
            line: 659,
            column: 126
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 658,
            column: 12
          },
          end: {
            line: 658,
            column: 107
          }
        }, {
          start: {
            line: 659,
            column: 12
          },
          end: {
            line: 659,
            column: 126
          }
        }],
        line: 657
      },
      '70': {
        loc: {
          start: {
            line: 664,
            column: 12
          },
          end: {
            line: 666,
            column: 13
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 664,
            column: 12
          },
          end: {
            line: 666,
            column: 13
          }
        }, {
          start: {
            line: 664,
            column: 12
          },
          end: {
            line: 666,
            column: 13
          }
        }],
        line: 664
      },
      '71': {
        loc: {
          start: {
            line: 672,
            column: 26
          },
          end: {
            line: 672,
            column: 72
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 672,
            column: 26
          },
          end: {
            line: 672,
            column: 37
          }
        }, {
          start: {
            line: 672,
            column: 41
          },
          end: {
            line: 672,
            column: 72
          }
        }],
        line: 672
      },
      '72': {
        loc: {
          start: {
            line: 673,
            column: 14
          },
          end: {
            line: 680,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 673,
            column: 14
          },
          end: {
            line: 680,
            column: 15
          }
        }, {
          start: {
            line: 673,
            column: 14
          },
          end: {
            line: 680,
            column: 15
          }
        }],
        line: 673
      },
      '73': {
        loc: {
          start: {
            line: 675,
            column: 25
          },
          end: {
            line: 675,
            column: 108
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 675,
            column: 38
          },
          end: {
            line: 675,
            column: 94
          }
        }, {
          start: {
            line: 675,
            column: 97
          },
          end: {
            line: 675,
            column: 108
          }
        }],
        line: 675
      },
      '74': {
        loc: {
          start: {
            line: 713,
            column: 20
          },
          end: {
            line: 713,
            column: 103
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 713,
            column: 53
          },
          end: {
            line: 713,
            column: 61
          }
        }, {
          start: {
            line: 713,
            column: 65
          },
          end: {
            line: 713,
            column: 102
          }
        }],
        line: 713
      },
      '75': {
        loc: {
          start: {
            line: 713,
            column: 65
          },
          end: {
            line: 713,
            column: 102
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 713,
            column: 87
          },
          end: {
            line: 713,
            column: 93
          }
        }, {
          start: {
            line: 713,
            column: 96
          },
          end: {
            line: 713,
            column: 102
          }
        }],
        line: 713
      },
      '76': {
        loc: {
          start: {
            line: 714,
            column: 23
          },
          end: {
            line: 714,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 714,
            column: 56
          },
          end: {
            line: 714,
            column: 57
          }
        }, {
          start: {
            line: 714,
            column: 60
          },
          end: {
            line: 714,
            column: 63
          }
        }],
        line: 714
      },
      '77': {
        loc: {
          start: {
            line: 719,
            column: 22
          },
          end: {
            line: 723,
            column: 15
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 719,
            column: 55
          },
          end: {
            line: 719,
            column: 64
          }
        }, {
          start: {
            line: 719,
            column: 67
          },
          end: {
            line: 723,
            column: 15
          }
        }],
        line: 719
      },
      '78': {
        loc: {
          start: {
            line: 725,
            column: 20
          },
          end: {
            line: 725,
            column: 103
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 725,
            column: 53
          },
          end: {
            line: 725,
            column: 61
          }
        }, {
          start: {
            line: 725,
            column: 65
          },
          end: {
            line: 725,
            column: 102
          }
        }],
        line: 725
      },
      '79': {
        loc: {
          start: {
            line: 725,
            column: 65
          },
          end: {
            line: 725,
            column: 102
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 725,
            column: 87
          },
          end: {
            line: 725,
            column: 93
          }
        }, {
          start: {
            line: 725,
            column: 96
          },
          end: {
            line: 725,
            column: 102
          }
        }],
        line: 725
      },
      '80': {
        loc: {
          start: {
            line: 726,
            column: 23
          },
          end: {
            line: 726,
            column: 63
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 726,
            column: 56
          },
          end: {
            line: 726,
            column: 57
          }
        }, {
          start: {
            line: 726,
            column: 60
          },
          end: {
            line: 726,
            column: 63
          }
        }],
        line: 726
      },
      '81': {
        loc: {
          start: {
            line: 731,
            column: 22
          },
          end: {
            line: 735,
            column: 15
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 731,
            column: 55
          },
          end: {
            line: 731,
            column: 64
          }
        }, {
          start: {
            line: 731,
            column: 67
          },
          end: {
            line: 735,
            column: 15
          }
        }],
        line: 731
      },
      '82': {
        loc: {
          start: {
            line: 752,
            column: 20
          },
          end: {
            line: 752,
            column: 43
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 752,
            column: 20
          },
          end: {
            line: 752,
            column: 33
          }
        }, {
          start: {
            line: 752,
            column: 37
          },
          end: {
            line: 752,
            column: 43
          }
        }],
        line: 752
      },
      '83': {
        loc: {
          start: {
            line: 762,
            column: 26
          },
          end: {
            line: 764,
            column: 126
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 763,
            column: 12
          },
          end: {
            line: 763,
            column: 107
          }
        }, {
          start: {
            line: 764,
            column: 12
          },
          end: {
            line: 764,
            column: 126
          }
        }],
        line: 762
      },
      '84': {
        loc: {
          start: {
            line: 769,
            column: 19
          },
          end: {
            line: 769,
            column: 66
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 769,
            column: 50
          },
          end: {
            line: 769,
            column: 60
          }
        }, {
          start: {
            line: 769,
            column: 63
          },
          end: {
            line: 769,
            column: 66
          }
        }],
        line: 769
      },
      '85': {
        loc: {
          start: {
            line: 770,
            column: 23
          },
          end: {
            line: 770,
            column: 70
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 770,
            column: 54
          },
          end: {
            line: 770,
            column: 60
          }
        }, {
          start: {
            line: 770,
            column: 63
          },
          end: {
            line: 770,
            column: 70
          }
        }],
        line: 770
      },
      '86': {
        loc: {
          start: {
            line: 770,
            column: 78
          },
          end: {
            line: 770,
            column: 126
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 770,
            column: 109
          },
          end: {
            line: 770,
            column: 115
          }
        }, {
          start: {
            line: 770,
            column: 118
          },
          end: {
            line: 770,
            column: 126
          }
        }],
        line: 770
      },
      '87': {
        loc: {
          start: {
            line: 773,
            column: 24
          },
          end: {
            line: 773,
            column: 70
          }
        },
        type: 'binary-expr',
        locations: [{
          start: {
            line: 773,
            column: 24
          },
          end: {
            line: 773,
            column: 35
          }
        }, {
          start: {
            line: 773,
            column: 39
          },
          end: {
            line: 773,
            column: 70
          }
        }],
        line: 773
      },
      '88': {
        loc: {
          start: {
            line: 775,
            column: 21
          },
          end: {
            line: 775,
            column: 75
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 775,
            column: 52
          },
          end: {
            line: 775,
            column: 62
          }
        }, {
          start: {
            line: 775,
            column: 65
          },
          end: {
            line: 775,
            column: 75
          }
        }],
        line: 775
      },
      '89': {
        loc: {
          start: {
            line: 777,
            column: 25
          },
          end: {
            line: 777,
            column: 72
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 777,
            column: 56
          },
          end: {
            line: 777,
            column: 62
          }
        }, {
          start: {
            line: 777,
            column: 65
          },
          end: {
            line: 777,
            column: 72
          }
        }],
        line: 777
      },
      '90': {
        loc: {
          start: {
            line: 777,
            column: 80
          },
          end: {
            line: 777,
            column: 128
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 777,
            column: 111
          },
          end: {
            line: 777,
            column: 117
          }
        }, {
          start: {
            line: 777,
            column: 120
          },
          end: {
            line: 777,
            column: 128
          }
        }],
        line: 777
      },
      '91': {
        loc: {
          start: {
            line: 778,
            column: 26
          },
          end: {
            line: 778,
            column: 109
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 778,
            column: 39
          },
          end: {
            line: 778,
            column: 95
          }
        }, {
          start: {
            line: 778,
            column: 98
          },
          end: {
            line: 778,
            column: 109
          }
        }],
        line: 778
      },
      '92': {
        loc: {
          start: {
            line: 789,
            column: 19
          },
          end: {
            line: 789,
            column: 66
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 789,
            column: 50
          },
          end: {
            line: 789,
            column: 60
          }
        }, {
          start: {
            line: 789,
            column: 63
          },
          end: {
            line: 789,
            column: 66
          }
        }],
        line: 789
      },
      '93': {
        loc: {
          start: {
            line: 791,
            column: 23
          },
          end: {
            line: 791,
            column: 70
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 791,
            column: 54
          },
          end: {
            line: 791,
            column: 60
          }
        }, {
          start: {
            line: 791,
            column: 63
          },
          end: {
            line: 791,
            column: 70
          }
        }],
        line: 791
      },
      '94': {
        loc: {
          start: {
            line: 791,
            column: 78
          },
          end: {
            line: 791,
            column: 126
          }
        },
        type: 'cond-expr',
        locations: [{
          start: {
            line: 791,
            column: 109
          },
          end: {
            line: 791,
            column: 115
          }
        }, {
          start: {
            line: 791,
            column: 118
          },
          end: {
            line: 791,
            column: 126
          }
        }],
        line: 791
      },
      '95': {
        loc: {
          start: {
            line: 806,
            column: 14
          },
          end: {
            line: 843,
            column: 15
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 806,
            column: 14
          },
          end: {
            line: 843,
            column: 15
          }
        }, {
          start: {
            line: 806,
            column: 14
          },
          end: {
            line: 843,
            column: 15
          }
        }],
        line: 806
      },
      '96': {
        loc: {
          start: {
            line: 809,
            column: 16
          },
          end: {
            line: 811,
            column: 17
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 809,
            column: 16
          },
          end: {
            line: 811,
            column: 17
          }
        }, {
          start: {
            line: 809,
            column: 16
          },
          end: {
            line: 811,
            column: 17
          }
        }],
        line: 809
      },
      '97': {
        loc: {
          start: {
            line: 938,
            column: 6
          },
          end: {
            line: 942,
            column: 7
          }
        },
        type: 'if',
        locations: [{
          start: {
            line: 938,
            column: 6
          },
          end: {
            line: 942,
            column: 7
          }
        }, {
          start: {
            line: 938,
            column: 6
          },
          end: {
            line: 942,
            column: 7
          }
        }],
        line: 938
      }
    },
    s: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0,
      '14': 0,
      '15': 0,
      '16': 0,
      '17': 0,
      '18': 0,
      '19': 0,
      '20': 0,
      '21': 0,
      '22': 0,
      '23': 0,
      '24': 0,
      '25': 0,
      '26': 0,
      '27': 0,
      '28': 0,
      '29': 0,
      '30': 0,
      '31': 0,
      '32': 0,
      '33': 0,
      '34': 0,
      '35': 0,
      '36': 0,
      '37': 0,
      '38': 0,
      '39': 0,
      '40': 0,
      '41': 0,
      '42': 0,
      '43': 0,
      '44': 0,
      '45': 0,
      '46': 0,
      '47': 0,
      '48': 0,
      '49': 0,
      '50': 0,
      '51': 0,
      '52': 0,
      '53': 0,
      '54': 0,
      '55': 0,
      '56': 0,
      '57': 0,
      '58': 0,
      '59': 0,
      '60': 0,
      '61': 0,
      '62': 0,
      '63': 0,
      '64': 0,
      '65': 0,
      '66': 0,
      '67': 0,
      '68': 0,
      '69': 0,
      '70': 0,
      '71': 0,
      '72': 0,
      '73': 0,
      '74': 0,
      '75': 0,
      '76': 0,
      '77': 0,
      '78': 0,
      '79': 0,
      '80': 0,
      '81': 0,
      '82': 0,
      '83': 0,
      '84': 0,
      '85': 0,
      '86': 0,
      '87': 0,
      '88': 0,
      '89': 0,
      '90': 0,
      '91': 0,
      '92': 0,
      '93': 0,
      '94': 0,
      '95': 0,
      '96': 0,
      '97': 0,
      '98': 0,
      '99': 0,
      '100': 0,
      '101': 0,
      '102': 0,
      '103': 0,
      '104': 0,
      '105': 0,
      '106': 0,
      '107': 0,
      '108': 0,
      '109': 0,
      '110': 0,
      '111': 0,
      '112': 0,
      '113': 0,
      '114': 0,
      '115': 0,
      '116': 0,
      '117': 0,
      '118': 0,
      '119': 0,
      '120': 0,
      '121': 0,
      '122': 0,
      '123': 0,
      '124': 0,
      '125': 0,
      '126': 0,
      '127': 0,
      '128': 0,
      '129': 0,
      '130': 0,
      '131': 0,
      '132': 0,
      '133': 0,
      '134': 0,
      '135': 0,
      '136': 0,
      '137': 0,
      '138': 0,
      '139': 0,
      '140': 0,
      '141': 0,
      '142': 0,
      '143': 0,
      '144': 0,
      '145': 0,
      '146': 0,
      '147': 0,
      '148': 0,
      '149': 0,
      '150': 0,
      '151': 0,
      '152': 0,
      '153': 0,
      '154': 0,
      '155': 0,
      '156': 0,
      '157': 0,
      '158': 0,
      '159': 0,
      '160': 0,
      '161': 0,
      '162': 0,
      '163': 0,
      '164': 0,
      '165': 0,
      '166': 0,
      '167': 0,
      '168': 0,
      '169': 0,
      '170': 0,
      '171': 0,
      '172': 0,
      '173': 0,
      '174': 0,
      '175': 0,
      '176': 0,
      '177': 0,
      '178': 0,
      '179': 0,
      '180': 0,
      '181': 0,
      '182': 0,
      '183': 0,
      '184': 0,
      '185': 0,
      '186': 0,
      '187': 0,
      '188': 0,
      '189': 0,
      '190': 0,
      '191': 0,
      '192': 0
    },
    f: {
      '0': 0,
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 0,
      '8': 0,
      '9': 0,
      '10': 0,
      '11': 0,
      '12': 0,
      '13': 0
    },
    b: {
      '0': [0, 0],
      '1': [0, 0],
      '2': [0, 0],
      '3': [0, 0],
      '4': [0, 0],
      '5': [0, 0],
      '6': [0, 0],
      '7': [0, 0],
      '8': [0, 0],
      '9': [0, 0],
      '10': [0, 0],
      '11': [0],
      '12': [0, 0],
      '13': [0, 0],
      '14': [0, 0],
      '15': [0, 0],
      '16': [0, 0],
      '17': [0, 0],
      '18': [0, 0],
      '19': [0, 0],
      '20': [0, 0],
      '21': [0, 0],
      '22': [0, 0],
      '23': [0, 0],
      '24': [0, 0],
      '25': [0, 0],
      '26': [0, 0],
      '27': [0, 0],
      '28': [0, 0],
      '29': [0, 0],
      '30': [0, 0],
      '31': [0, 0],
      '32': [0, 0],
      '33': [0, 0],
      '34': [0, 0],
      '35': [0, 0],
      '36': [0, 0],
      '37': [0, 0],
      '38': [0, 0],
      '39': [0, 0],
      '40': [0, 0],
      '41': [0, 0],
      '42': [0, 0],
      '43': [0, 0],
      '44': [0, 0],
      '45': [0, 0],
      '46': [0, 0],
      '47': [0, 0],
      '48': [0, 0],
      '49': [0, 0],
      '50': [0, 0],
      '51': [0, 0],
      '52': [0, 0],
      '53': [0, 0],
      '54': [0, 0],
      '55': [0, 0],
      '56': [0, 0],
      '57': [0, 0],
      '58': [0, 0],
      '59': [0, 0],
      '60': [0, 0],
      '61': [0, 0],
      '62': [0, 0],
      '63': [0, 0],
      '64': [0, 0],
      '65': [0, 0],
      '66': [0, 0],
      '67': [0, 0],
      '68': [0, 0],
      '69': [0, 0],
      '70': [0, 0],
      '71': [0, 0],
      '72': [0, 0],
      '73': [0, 0],
      '74': [0, 0],
      '75': [0, 0],
      '76': [0, 0],
      '77': [0, 0],
      '78': [0, 0],
      '79': [0, 0],
      '80': [0, 0],
      '81': [0, 0],
      '82': [0, 0],
      '83': [0, 0],
      '84': [0, 0],
      '85': [0, 0],
      '86': [0, 0],
      '87': [0, 0],
      '88': [0, 0],
      '89': [0, 0],
      '90': [0, 0],
      '91': [0, 0],
      '92': [0, 0],
      '93': [0, 0],
      '94': [0, 0],
      '95': [0, 0],
      '96': [0, 0],
      '97': [0, 0]
    },
    _coverageSchema: '332fd63041d2c1bcb487cc26dd0d5f7d97098a6c'
  },
      coverage = global[gcv] || (global[gcv] = {});

  if (coverage[path] && coverage[path].hash === hash) {
    return coverage[path];
  }

  coverageData.hash = hash;
  return coverage[path] = coverageData;
}();

cov_11z8x1t1ks.s[0]++;

angular.module('app').directive('dashboardCombineIndicator', function ($timeout) {
  cov_11z8x1t1ks.f[0]++;
  cov_11z8x1t1ks.s[1]++;

  return {
    restrict: 'E',
    scope: {
      idcomponent: '@',
      data: '=',
      analyse: '=',
      titleDashboard: '@',
      height: '@',
      print: '=',
      hidebutton: '='
    },
    templateUrl: 'app/views/common/dashboard-combine-indicator.html',

    controller: function controller($scope, $deltaNumber, $deltadate, $rootScope, $translate, $filter, $LOGINService, $CRUDService) {
      var PATH = (cov_11z8x1t1ks.s[2]++, 'Indicateurs');
      var PATHINDIC = (cov_11z8x1t1ks.s[3]++, 'DecoupageIndic');
      var PATHANALYSE = (cov_11z8x1t1ks.s[4]++, 'Analyse');
      var firstTabChart = (cov_11z8x1t1ks.s[5]++, {
        _formatItem: function _formatItem(ele) {
          var el = (cov_11z8x1t1ks.s[6]++, {
            vp: $deltaNumber.convertToNumber(ele.vp, undefined),
            vr: $deltaNumber.convertToNumber(ele.vr, undefined),
            cp: $deltaNumber.convertToNumber(ele.cp, undefined),
            cr: $deltaNumber.convertToNumber(ele.cr, undefined),
            tr: null,
            ec: null
          });
          cov_11z8x1t1ks.s[7]++;
          if (!((cov_11z8x1t1ks.b[1][0]++, angular.isUndefined(el.vp)) || (cov_11z8x1t1ks.b[1][1]++, angular.isUndefined(el.vr)))) {
            cov_11z8x1t1ks.b[0][0]++;
            cov_11z8x1t1ks.s[8]++;

            if (el.vp != 0) {
              cov_11z8x1t1ks.b[2][0]++;
              cov_11z8x1t1ks.s[9]++;

              el.tr = $deltaNumber.formatNumber(($scope.analyse.TYPE == 2 ? (cov_11z8x1t1ks.b[3][0]++, 100) : (cov_11z8x1t1ks.b[3][1]++, 1)) * el.vr / el.vp, null, $scope.analyse.TYPE == 2 ? (cov_11z8x1t1ks.b[4][0]++, 2) : (cov_11z8x1t1ks.b[4][1]++, 4));
            } else {
              cov_11z8x1t1ks.b[2][1]++;
            }
            cov_11z8x1t1ks.s[10]++;
            el.ec = el.vp - el.vr;
          } else {
            cov_11z8x1t1ks.b[0][1]++;
          }
          cov_11z8x1t1ks.s[11]++;
          return el;
        },
        process: function process() {
          var _this = this;

          cov_11z8x1t1ks.s[12]++;

          if ((cov_11z8x1t1ks.b[6][0]++, $scope.analyse.TYPE != 1) && (cov_11z8x1t1ks.b[6][1]++, $scope.analyse.OPS.length > 0)) {
            cov_11z8x1t1ks.b[5][0]++;
            cov_11z8x1t1ks.s[13]++;

            return this._processDesagregate();
          } else {
            cov_11z8x1t1ks.b[5][1]++;
          }

          cov_11z8x1t1ks.s[14]++;
          if ($scope.analyse.level == 3) {
            cov_11z8x1t1ks.b[7][0]++;
            cov_11z8x1t1ks.s[15]++;

            return this._processPeriod();
          } else {
            cov_11z8x1t1ks.b[7][1]++;
          }
          cov_11z8x1t1ks.s[16]++;
          $CRUDService.getAll(PATHINDIC, {
            get: 'suivi_n_g',
            valid: $scope.analyse.valid,
            ID_INDIC: $scope.analyse.ID_INDIC,
            CODE_NG: $scope.analyse.CODE_NG,
            CODE_CL: $scope.analyse.CODE_CL,
            OPS: angular.toJson($scope.analyse.OPS)
          }, function (data) {
            cov_11z8x1t1ks.f[1]++;
            cov_11z8x1t1ks.s[17]++;

            if ($scope.analyse.TYPE == 1) {
              cov_11z8x1t1ks.b[8][0]++;
              cov_11z8x1t1ks.s[18]++;

              return _this._processPeriod(data);
            } else {
              cov_11z8x1t1ks.b[8][1]++;
            }

            var lineData = (cov_11z8x1t1ks.s[19]++, []);
            cov_11z8x1t1ks.s[20]++;
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var itemEl = _step.value;

                var _ = (cov_11z8x1t1ks.s[21]++, {
                  arc_id: itemEl.arc_id,
                  c: itemEl.c,
                  d: itemEl.d,
                  id: itemEl.id,
                  id_: itemEl.id_,
                  l: itemEl.l,
                  osm_id: itemEl.osm_id,
                  osm_type: itemEl.osm_type,
                  vcg: itemEl.vcg,
                  vrg: itemEl.vrg,
                  x: itemEl.x,
                  y: itemEl.y
                });
                cov_11z8x1t1ks.s[22]++;
                if ($scope.analyse.level == 1) {
                  cov_11z8x1t1ks.b[9][0]++;
                  cov_11z8x1t1ks.s[23]++;

                  lineData.push(Object.assign(_, _this._formatItem(itemEl)));
                  cov_11z8x1t1ks.s[24]++;
                  continue;
                } else {
                  cov_11z8x1t1ks.b[9][1]++;
                }
                cov_11z8x1t1ks.s[25]++;
                var _iteratorNormalCompletion2 = true;
                var _didIteratorError2 = false;
                var _iteratorError2 = undefined;

                try {
                  for (var _iterator2 = itemEl.ye[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                    var ele = _step2.value;
                    cov_11z8x1t1ks.s[26]++;

                    if (ele.y == $scope.analyse.year) {
                      cov_11z8x1t1ks.b[10][0]++;
                      cov_11z8x1t1ks.s[27]++;

                      lineData.push(Object.assign(_, _this._formatItem(ele)));
                      cov_11z8x1t1ks.s[28]++;
                      break;
                    } else {
                      cov_11z8x1t1ks.b[10][1]++;
                    }
                  }
                } catch (err) {
                  _didIteratorError2 = true;
                  _iteratorError2 = err;
                } finally {
                  try {
                    if (!_iteratorNormalCompletion2 && _iterator2.return) {
                      _iterator2.return();
                    }
                  } finally {
                    if (_didIteratorError2) {
                      throw _iteratorError2;
                    }
                  }
                }
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            cov_11z8x1t1ks.s[29]++;
            _this._drawGraph(lineData);
          });
        },
        _processPeriod: function _processPeriod() {
          var _this2 = this;

          var dataGlobal = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (cov_11z8x1t1ks.b[11][0]++, []);
          cov_11z8x1t1ks.s[30]++;

          $CRUDService.getAll(PATHINDIC, {
            get: 'suivi_n_p',
            CODE_CL: $scope.analyse.CODE_CL,
            CODE_NG: $scope.analyse.CODE_NG,
            ID_INDIC: $scope.analyse.ID_INDIC,
            OPS: angular.toJson($scope.analyse.OPS),
            annee: $scope.analyse.year,
            valid: $scope.analyse.valid
          }, function (data) {
            cov_11z8x1t1ks.f[2]++;
            cov_11z8x1t1ks.s[31]++;

            if ($scope.analyse.TYPE == 1) {
              cov_11z8x1t1ks.b[12][0]++;
              cov_11z8x1t1ks.s[32]++;

              return _this2._processTable(dataGlobal, data);
            } else {
              cov_11z8x1t1ks.b[12][1]++;
            }

            var lineData = (cov_11z8x1t1ks.s[33]++, []);
            cov_11z8x1t1ks.s[34]++;
            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
              for (var _iterator3 = data[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                var itemEl = _step3.value;

                var _ = (cov_11z8x1t1ks.s[35]++, {
                  id: itemEl.id,
                  l: itemEl.l
                });
                cov_11z8x1t1ks.s[36]++;
                var _iteratorNormalCompletion4 = true;
                var _didIteratorError4 = false;
                var _iteratorError4 = undefined;

                try {
                  for (var _iterator4 = itemEl.d[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                    var ele = _step4.value;
                    cov_11z8x1t1ks.s[37]++;

                    if (ele.n == $scope.analyse.period) {
                      cov_11z8x1t1ks.b[13][0]++;
                      cov_11z8x1t1ks.s[38]++;

                      lineData.push(Object.assign(_, _this2._formatItem(ele)));
                      cov_11z8x1t1ks.s[39]++;
                      break;
                    } else {
                      cov_11z8x1t1ks.b[13][1]++;
                    }
                  }
                } catch (err) {
                  _didIteratorError4 = true;
                  _iteratorError4 = err;
                } finally {
                  try {
                    if (!_iteratorNormalCompletion4 && _iterator4.return) {
                      _iterator4.return();
                    }
                  } finally {
                    if (_didIteratorError4) {
                      throw _iteratorError4;
                    }
                  }
                }
              }
            } catch (err) {
              _didIteratorError3 = true;
              _iteratorError3 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion3 && _iterator3.return) {
                  _iterator3.return();
                }
              } finally {
                if (_didIteratorError3) {
                  throw _iteratorError3;
                }
              }
            }

            cov_11z8x1t1ks.s[40]++;
            _this2._drawGraph(lineData);
          });
        },
        _processDesagregatePeriod: function _processDesagregatePeriod() {
          var _this3 = this;

          var size = (cov_11z8x1t1ks.s[41]++, $scope.analyse.OPS.length);
          var lineData = (cov_11z8x1t1ks.s[42]++, []);
          cov_11z8x1t1ks.s[43]++;
          var _iteratorNormalCompletion5 = true;
          var _didIteratorError5 = false;
          var _iteratorError5 = undefined;

          try {
            var _loop = function _loop() {
              var op = _step5.value;
              cov_11z8x1t1ks.s[44]++;

              $CRUDService.getAll(PATHINDIC, {
                get: 'suivi_n_p',
                CODE_CL: $scope.analyse.CODE_CL,
                CODE_NG: $scope.analyse.CODE_NG,
                ID_INDIC: $scope.analyse.ID_INDIC,
                OPS: angular.toJson([op.id]),
                annee: $scope.analyse.year,
                valid: $scope.analyse.valid
              }, function (data) {
                cov_11z8x1t1ks.f[3]++;
                cov_11z8x1t1ks.s[45]++;

                size--;
                cov_11z8x1t1ks.s[46]++;
                var _iteratorNormalCompletion6 = true;
                var _didIteratorError6 = false;
                var _iteratorError6 = undefined;

                try {
                  var _loop2 = function _loop2() {
                    var itemEl = _step6.value;

                    var _ = (cov_11z8x1t1ks.s[47]++, (cov_11z8x1t1ks.b[14][0]++, lineData.find(function (value) {
                      cov_11z8x1t1ks.f[4]++;
                      cov_11z8x1t1ks.s[48]++;

                      return value.id == itemEl.id;
                    })) || (cov_11z8x1t1ks.b[14][1]++, {
                      id: itemEl.id,
                      l: itemEl.l,
                      newItem: true
                    }));
                    cov_11z8x1t1ks.s[49]++;
                    var _iteratorNormalCompletion7 = true;
                    var _didIteratorError7 = false;
                    var _iteratorError7 = undefined;

                    try {
                      for (var _iterator7 = itemEl.d[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
                        var ele = _step7.value;
                        cov_11z8x1t1ks.s[50]++;

                        if (ele.n == $scope.analyse.period) {
                          cov_11z8x1t1ks.b[15][0]++;
                          cov_11z8x1t1ks.s[51]++;

                          _[op.id] = _this3._formatItem(ele);
                          cov_11z8x1t1ks.s[52]++;
                          if (_.newItem) {
                            cov_11z8x1t1ks.b[16][0]++;
                            cov_11z8x1t1ks.s[53]++;

                            _.newItem = false;
                            cov_11z8x1t1ks.s[54]++;
                            lineData.push(_);
                          } else {
                            cov_11z8x1t1ks.b[16][1]++;
                          }
                          cov_11z8x1t1ks.s[55]++;
                          break;
                        } else {
                          cov_11z8x1t1ks.b[15][1]++;
                        }
                      }
                    } catch (err) {
                      _didIteratorError7 = true;
                      _iteratorError7 = err;
                    } finally {
                      try {
                        if (!_iteratorNormalCompletion7 && _iterator7.return) {
                          _iterator7.return();
                        }
                      } finally {
                        if (_didIteratorError7) {
                          throw _iteratorError7;
                        }
                      }
                    }
                  };

                  for (var _iterator6 = data[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
                    _loop2();
                  }
                } catch (err) {
                  _didIteratorError6 = true;
                  _iteratorError6 = err;
                } finally {
                  try {
                    if (!_iteratorNormalCompletion6 && _iterator6.return) {
                      _iterator6.return();
                    }
                  } finally {
                    if (_didIteratorError6) {
                      throw _iteratorError6;
                    }
                  }
                }

                cov_11z8x1t1ks.s[56]++;
                if (size == 0) {
                  cov_11z8x1t1ks.b[17][0]++;
                  cov_11z8x1t1ks.s[57]++;

                  _this3._drawDesagregateGraph(lineData);
                } else {
                  cov_11z8x1t1ks.b[17][1]++;
                }
              });
            };

            for (var _iterator5 = $scope.analyse.OPS[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
              _loop();
            }
          } catch (err) {
            _didIteratorError5 = true;
            _iteratorError5 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion5 && _iterator5.return) {
                _iterator5.return();
              }
            } finally {
              if (_didIteratorError5) {
                throw _iteratorError5;
              }
            }
          }
        },
        _processDesagregate: function _processDesagregate() {
          var _this4 = this;

          cov_11z8x1t1ks.s[58]++;

          if ($scope.analyse.level == 3) {
            cov_11z8x1t1ks.b[18][0]++;
            cov_11z8x1t1ks.s[59]++;

            return this._processDesagregatePeriod();
          } else {
            cov_11z8x1t1ks.b[18][1]++;
          }
          var size = (cov_11z8x1t1ks.s[60]++, $scope.analyse.OPS.length);
          var lineData = (cov_11z8x1t1ks.s[61]++, []);
          cov_11z8x1t1ks.s[62]++;
          var _iteratorNormalCompletion8 = true;
          var _didIteratorError8 = false;
          var _iteratorError8 = undefined;

          try {
            var _loop3 = function _loop3() {
              var op = _step8.value;
              cov_11z8x1t1ks.s[63]++;

              $CRUDService.getAll(PATHINDIC, {
                get: 'suivi_n_g',
                valid: $scope.analyse.valid,
                ID_INDIC: $scope.analyse.ID_INDIC,
                CODE_NG: $scope.analyse.CODE_NG,
                CODE_CL: $scope.analyse.CODE_CL,
                OPS: angular.toJson([op.id])
              }, function (data) {
                cov_11z8x1t1ks.f[5]++;
                cov_11z8x1t1ks.s[64]++;

                size--;
                cov_11z8x1t1ks.s[65]++;
                var _iteratorNormalCompletion9 = true;
                var _didIteratorError9 = false;
                var _iteratorError9 = undefined;

                try {
                  var _loop4 = function _loop4() {
                    var itemEl = _step9.value;

                    var _ = (cov_11z8x1t1ks.s[66]++, (cov_11z8x1t1ks.b[19][0]++, lineData.find(function (value) {
                      cov_11z8x1t1ks.f[6]++;
                      cov_11z8x1t1ks.s[67]++;

                      return value.id == itemEl.id;
                    })) || (cov_11z8x1t1ks.b[19][1]++, {
                      arc_id: itemEl.arc_id,
                      c: itemEl.c,
                      d: itemEl.d,
                      id: itemEl.id,
                      id_: itemEl.id_,
                      l: itemEl.l,
                      osm_id: itemEl.osm_id,
                      osm_type: itemEl.osm_type,
                      vcg: itemEl.vcg,
                      vrg: itemEl.vrg,
                      x: itemEl.x,
                      y: itemEl.y,
                      newItem: true
                    }));
                    cov_11z8x1t1ks.s[68]++;
                    if ($scope.analyse.level == 1) {
                      cov_11z8x1t1ks.b[20][0]++;
                      cov_11z8x1t1ks.s[69]++;

                      _[op.id] = _this4._formatItem(itemEl);
                      cov_11z8x1t1ks.s[70]++;
                      if (_.newItem) {
                        cov_11z8x1t1ks.b[21][0]++;
                        cov_11z8x1t1ks.s[71]++;

                        _.newItem = false;
                        cov_11z8x1t1ks.s[72]++;
                        lineData.push(_);
                      } else {
                        cov_11z8x1t1ks.b[21][1]++;
                      }
                      cov_11z8x1t1ks.s[73]++;
                      return 'continue';
                    } else {
                      cov_11z8x1t1ks.b[20][1]++;
                    }
                    cov_11z8x1t1ks.s[74]++;
                    var _iteratorNormalCompletion10 = true;
                    var _didIteratorError10 = false;
                    var _iteratorError10 = undefined;

                    try {
                      for (var _iterator10 = itemEl.ye[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
                        var ele = _step10.value;
                        cov_11z8x1t1ks.s[75]++;

                        if (ele.y == $scope.analyse.year) {
                          cov_11z8x1t1ks.b[22][0]++;
                          cov_11z8x1t1ks.s[76]++;

                          _[op.id] = _this4._formatItem(ele);
                          cov_11z8x1t1ks.s[77]++;
                          if (_.newItem) {
                            cov_11z8x1t1ks.b[23][0]++;
                            cov_11z8x1t1ks.s[78]++;

                            _.newItem = false;
                            cov_11z8x1t1ks.s[79]++;
                            lineData.push(_);
                          } else {
                            cov_11z8x1t1ks.b[23][1]++;
                          }
                          cov_11z8x1t1ks.s[80]++;
                          break;
                        } else {
                          cov_11z8x1t1ks.b[22][1]++;
                        }
                      }
                    } catch (err) {
                      _didIteratorError10 = true;
                      _iteratorError10 = err;
                    } finally {
                      try {
                        if (!_iteratorNormalCompletion10 && _iterator10.return) {
                          _iterator10.return();
                        }
                      } finally {
                        if (_didIteratorError10) {
                          throw _iteratorError10;
                        }
                      }
                    }
                  };

                  for (var _iterator9 = data[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
                    var _ret4 = _loop4();

                    if (_ret4 === 'continue') continue;
                  }
                } catch (err) {
                  _didIteratorError9 = true;
                  _iteratorError9 = err;
                } finally {
                  try {
                    if (!_iteratorNormalCompletion9 && _iterator9.return) {
                      _iterator9.return();
                    }
                  } finally {
                    if (_didIteratorError9) {
                      throw _iteratorError9;
                    }
                  }
                }

                cov_11z8x1t1ks.s[81]++;
                if (size == 0) {
                  cov_11z8x1t1ks.b[24][0]++;
                  cov_11z8x1t1ks.s[82]++;

                  _this4._drawDesagregateGraph(lineData);
                } else {
                  cov_11z8x1t1ks.b[24][1]++;
                }
              });
            };

            for (var _iterator8 = $scope.analyse.OPS[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
              _loop3();
            }
          } catch (err) {
            _didIteratorError8 = true;
            _iteratorError8 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion8 && _iterator8.return) {
                _iterator8.return();
              }
            } finally {
              if (_didIteratorError8) {
                throw _iteratorError8;
              }
            }
          }
        },
        _drawDesagregateGraph: function _drawDesagregateGraph(dataSource) {
          cov_11z8x1t1ks.s[83]++;

          if ($scope.analyse.typeAnalyse == 2) {
            cov_11z8x1t1ks.b[25][0]++;
            cov_11z8x1t1ks.s[84]++;

            return this._drawDesagregateSecondGraph(dataSource);
          } else {
            cov_11z8x1t1ks.b[25][1]++;
          }
          var series = (cov_11z8x1t1ks.s[85]++, []);
          cov_11z8x1t1ks.s[86]++;
          var _iteratorNormalCompletion11 = true;
          var _didIteratorError11 = false;
          var _iteratorError11 = undefined;

          try {
            for (var _iterator11 = $scope.analyse.OPS[Symbol.iterator](), _step11; !(_iteratorNormalCompletion11 = (_step11 = _iterator11.next()).done); _iteratorNormalCompletion11 = true) {
              var _op = _step11.value;
              cov_11z8x1t1ks.s[87]++;

              series.push({ // FILL
                type: $scope.analyse.barChart ? (cov_11z8x1t1ks.b[26][0]++, 'Column') : (cov_11z8x1t1ks.b[26][1]++, 'Line'),
                opacity: $scope.analyse.barChart ? (cov_11z8x1t1ks.b[27][0]++, 1) : (cov_11z8x1t1ks.b[27][1]++, 0.5),
                dataSource: dataSource,
                xName: 'l', yName: _op.id + '_tr', name: _op.label,
                width: 3,
                marker: $scope.analyse.barChart ? (cov_11z8x1t1ks.b[28][0]++, undefined) : (cov_11z8x1t1ks.b[28][1]++, {
                  visible: true,
                  width: 10,
                  height: 10
                })
              });
              cov_11z8x1t1ks.s[88]++;
              var _iteratorNormalCompletion12 = true;
              var _didIteratorError12 = false;
              var _iteratorError12 = undefined;

              try {
                for (var _iterator12 = dataSource[Symbol.iterator](), _step12; !(_iteratorNormalCompletion12 = (_step12 = _iterator12.next()).done); _iteratorNormalCompletion12 = true) {
                  var item = _step12.value;
                  cov_11z8x1t1ks.s[89]++;

                  item[_op.id + '_tr'] = ((cov_11z8x1t1ks.b[29][0]++, item[_op.id]) || (cov_11z8x1t1ks.b[29][1]++, { tr: null })).tr;
                }
              } catch (err) {
                _didIteratorError12 = true;
                _iteratorError12 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion12 && _iterator12.return) {
                    _iterator12.return();
                  }
                } finally {
                  if (_didIteratorError12) {
                    throw _iteratorError12;
                  }
                }
              }
            }
          } catch (err) {
            _didIteratorError11 = true;
            _iteratorError11 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion11 && _iterator11.return) {
                _iterator11.return();
              }
            } finally {
              if (_didIteratorError11) {
                throw _iteratorError11;
              }
            }
          }

          cov_11z8x1t1ks.s[90]++;
          $scope.graphParams = {
            locale: $LOGINService.getLang(),
            // Initializing Primary X and Y Axis
            primaryXAxis: {
              // title: 'Years',
              interval: 1,
              labelIntersectAction: 'Rotate45',
              valueType: 'Category',
              majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
              majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
              lineStyle: { width: 0 }
            },
            primaryYAxis: {
              valueType: 'Double',
              lineStyle: { width: 0 },
              majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
              minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
              labelFormat: 'P'
            },
            chartArea: {
              border: {
                width: 0
              }
            },
            useGroupingSeparator: true,
            // Initializing Chart Series
            series: series,
            textRender: function textRender(args, a, b) {},

            width: '100%',
            height: (cov_11z8x1t1ks.b[30][0]++, $scope.height) || (cov_11z8x1t1ks.b[30][1]++, '100%'),
            // Initializing Chart title
            legendSettings: {
              visible: true
            },
            tooltip: { enable: true, shared: true }
          };
        },
        _drawGraph: function _drawGraph(dataSource) {
          cov_11z8x1t1ks.s[91]++;

          if ($scope.analyse.typeAnalyse == 2) {
            cov_11z8x1t1ks.b[31][0]++;
            cov_11z8x1t1ks.s[92]++;

            return this._drawSecondGraph(dataSource);
          } else {
            cov_11z8x1t1ks.b[31][1]++;
          }
          cov_11z8x1t1ks.s[93]++;
          if ($scope.analyse.TYPE == 2) {
            cov_11z8x1t1ks.b[32][0]++;
            cov_11z8x1t1ks.s[94]++;

            return this._drawPieChart(dataSource);
          } else {
            cov_11z8x1t1ks.b[32][1]++;
          }
          cov_11z8x1t1ks.s[95]++;
          $scope.graphParams = {
            locale: $LOGINService.getLang(),
            // Initializing Primary X and Y Axis
            primaryXAxis: {
              // title: 'Years',
              interval: 1,
              labelIntersectAction: 'Rotate45',
              valueType: 'Category',
              majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
              majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
              lineStyle: { width: 0 }
            },
            primaryYAxis: {
              valueType: 'Double',
              lineStyle: { width: 0 },
              majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
              minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
              labelFormat: 'P'
            },
            chartArea: {
              border: {
                width: 0
              }
            },
            useGroupingSeparator: true,
            // Initializing Chart Series
            series: [{ // FILL
              type: $scope.analyse.barChart ? (cov_11z8x1t1ks.b[33][0]++, 'Column') : (cov_11z8x1t1ks.b[33][1]++, 'Line'),
              opacity: $scope.analyse.barChart ? (cov_11z8x1t1ks.b[34][0]++, 1) : (cov_11z8x1t1ks.b[34][1]++, 0.5),
              dataSource: dataSource,
              fill: 'rgba(' + $scope.analyse.color + ', 0.7)',
              xName: 'l', yName: 'tr', name: $translate.instant('INDICATOR.DASHBOARD.LEGEND'),
              width: 3,
              marker: $scope.analyse.barChart ? (cov_11z8x1t1ks.b[35][0]++, undefined) : (cov_11z8x1t1ks.b[35][1]++, {
                visible: true,
                width: 10,
                height: 10
              })
            }],
            textRender: function textRender(args, a, b) {},

            width: '100%',
            height: (cov_11z8x1t1ks.b[36][0]++, $scope.height) || (cov_11z8x1t1ks.b[36][1]++, '100%'),
            // Initializing Chart title
            legendSettings: {
              visible: true
            },
            tooltip: { enable: true, shared: true }
          };
        },
        _drawSecondGraph: function _drawSecondGraph(dataSource) {
          cov_11z8x1t1ks.s[96]++;

          $scope.graphParams = {
            locale: $LOGINService.getLang(),
            // Initializing Primary X and Y Axis
            primaryXAxis: {
              // title: 'Years',
              interval: 1,
              labelIntersectAction: 'Rotate45',
              valueType: 'Category',
              majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
              majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
              lineStyle: { width: 0 }
            },
            primaryYAxis: {
              valueType: 'Double',
              lineStyle: { width: 0 },
              majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
              minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
              labelFormat: 'N'
            },
            chartArea: {
              border: {
                width: 0
              }
            },
            useGroupingSeparator: true,
            // Initializing Chart Series
            series: [{ // FILL
              type: $scope.analyse.target.barChart ? (cov_11z8x1t1ks.b[37][0]++, 'Column') : (cov_11z8x1t1ks.b[37][1]++, $scope.analyse.FILL ? (cov_11z8x1t1ks.b[38][0]++, 'Area') : (cov_11z8x1t1ks.b[38][1]++, 'Line')),
              opacity: $scope.analyse.target.barChart ? (cov_11z8x1t1ks.b[39][0]++, 1) : (cov_11z8x1t1ks.b[39][1]++, 0.5),
              dataSource: dataSource,
              // dataLabel: {visible: true, position: 'Outside'},
              fill: 'rgba(' + $scope.analyse.target.color + ', 0.7)',
              xName: 'l', yName: 'vp', name: $translate.instant('INDICATOR.DASHBOARD.ACTUALS'),
              width: 3,
              marker: $scope.analyse.target.barChart ? (cov_11z8x1t1ks.b[40][0]++, undefined) : (cov_11z8x1t1ks.b[40][1]++, {
                visible: true,
                width: 10,
                height: 10
              })
            }, { // FILL
              type: $scope.analyse.actual.barChart ? (cov_11z8x1t1ks.b[41][0]++, 'Column') : (cov_11z8x1t1ks.b[41][1]++, $scope.analyse.FILL ? (cov_11z8x1t1ks.b[42][0]++, 'Area') : (cov_11z8x1t1ks.b[42][1]++, 'Line')),
              opacity: $scope.analyse.actual.barChart ? (cov_11z8x1t1ks.b[43][0]++, 1) : (cov_11z8x1t1ks.b[43][1]++, 0.5), // 'Area'
              dataSource: dataSource,
              // dataLabel: {visible: true, position: 'Outside'},
              fill: 'rgba(' + $scope.analyse.actual.color + ', 0.7)',
              xName: 'l', yName: 'vr', name: $translate.instant('INDICATOR.DASHBOARD.TARGETS'),
              width: 3,
              marker: $scope.analyse.actual.barChart ? (cov_11z8x1t1ks.b[44][0]++, undefined) : (cov_11z8x1t1ks.b[44][1]++, {
                visible: true,
                width: 10,
                height: 10
              })
            }],
            textRender: function textRender(args, a, b) {},

            width: '100%',
            height: (cov_11z8x1t1ks.b[45][0]++, $scope.height) || (cov_11z8x1t1ks.b[45][1]++, '100%'),
            // Initializing Chart title
            legendSettings: {
              visible: true
            },
            tooltip: { enable: true, shared: true }
          };
        },
        _drawDesagregateSecondGraph: function _drawDesagregateSecondGraph(dataSource) {
          var series = (cov_11z8x1t1ks.s[97]++, []);
          cov_11z8x1t1ks.s[98]++;
          var _iteratorNormalCompletion13 = true;
          var _didIteratorError13 = false;
          var _iteratorError13 = undefined;

          try {
            for (var _iterator13 = $scope.analyse.OPS[Symbol.iterator](), _step13; !(_iteratorNormalCompletion13 = (_step13 = _iterator13.next()).done); _iteratorNormalCompletion13 = true) {
              var _op2 = _step13.value;
              cov_11z8x1t1ks.s[99]++;

              series.push({ // FILL
                type: $scope.analyse.target.barChart ? (cov_11z8x1t1ks.b[46][0]++, 'Column') : (cov_11z8x1t1ks.b[46][1]++, $scope.analyse.FILL ? (cov_11z8x1t1ks.b[47][0]++, 'Area') : (cov_11z8x1t1ks.b[47][1]++, 'Line')),
                opacity: $scope.analyse.target.barChart ? (cov_11z8x1t1ks.b[48][0]++, 1) : (cov_11z8x1t1ks.b[48][1]++, 0.5),
                dataSource: dataSource,
                xName: 'l', yName: _op2.id + '_vp', name: _op2.label + ' (' + $translate.instant('INDICATOR.DASHBOARD.ACTUALS') + ')',
                width: 3,
                marker: $scope.analyse.target.barChart ? (cov_11z8x1t1ks.b[49][0]++, undefined) : (cov_11z8x1t1ks.b[49][1]++, {
                  visible: true,
                  width: 10,
                  height: 10
                })
              });

              cov_11z8x1t1ks.s[100]++;
              series.push({ // FILL
                type: $scope.analyse.actual.barChart ? (cov_11z8x1t1ks.b[50][0]++, 'Column') : (cov_11z8x1t1ks.b[50][1]++, $scope.analyse.FILL ? (cov_11z8x1t1ks.b[51][0]++, 'Area') : (cov_11z8x1t1ks.b[51][1]++, 'Line')),
                opacity: $scope.analyse.actual.barChart ? (cov_11z8x1t1ks.b[52][0]++, 1) : (cov_11z8x1t1ks.b[52][1]++, 0.5), // 'Area'
                dataSource: dataSource,
                xName: 'l', yName: _op2.id + '_vr', name: _op2.label + ' (' + $translate.instant('INDICATOR.DASHBOARD.TARGETS') + ')',
                width: 3,
                marker: $scope.analyse.actual.barChart ? (cov_11z8x1t1ks.b[53][0]++, undefined) : (cov_11z8x1t1ks.b[53][1]++, {
                  visible: true,
                  width: 10,
                  height: 10
                })
              });

              cov_11z8x1t1ks.s[101]++;
              var _iteratorNormalCompletion14 = true;
              var _didIteratorError14 = false;
              var _iteratorError14 = undefined;

              try {
                for (var _iterator14 = dataSource[Symbol.iterator](), _step14; !(_iteratorNormalCompletion14 = (_step14 = _iterator14.next()).done); _iteratorNormalCompletion14 = true) {
                  var item = _step14.value;
                  cov_11z8x1t1ks.s[102]++;

                  item[_op2.id + '_vp'] = ((cov_11z8x1t1ks.b[54][0]++, item[_op2.id]) || (cov_11z8x1t1ks.b[54][1]++, { vp: null })).vp;
                  cov_11z8x1t1ks.s[103]++;
                  item[_op2.id + '_vr'] = ((cov_11z8x1t1ks.b[55][0]++, item[_op2.id]) || (cov_11z8x1t1ks.b[55][1]++, { vr: null })).vr;
                }
              } catch (err) {
                _didIteratorError14 = true;
                _iteratorError14 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion14 && _iterator14.return) {
                    _iterator14.return();
                  }
                } finally {
                  if (_didIteratorError14) {
                    throw _iteratorError14;
                  }
                }
              }
            }
          } catch (err) {
            _didIteratorError13 = true;
            _iteratorError13 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion13 && _iterator13.return) {
                _iterator13.return();
              }
            } finally {
              if (_didIteratorError13) {
                throw _iteratorError13;
              }
            }
          }

          cov_11z8x1t1ks.s[104]++;
          $scope.graphParams = {
            locale: $LOGINService.getLang(),
            // Initializing Primary X and Y Axis
            primaryXAxis: {
              // title: 'Years',
              interval: 1,
              labelIntersectAction: 'Rotate45',
              valueType: 'Category',
              majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
              majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
              lineStyle: { width: 0 }
            },
            primaryYAxis: {
              valueType: 'Double',
              lineStyle: { width: 0 },
              majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
              minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
              labelFormat: 'N'
            },
            chartArea: {
              border: {
                width: 0
              }
            },
            useGroupingSeparator: true,
            // Initializing Chart Series
            series: series,
            textRender: function textRender(args, a, b) {},

            width: '100%',
            height: (cov_11z8x1t1ks.b[56][0]++, $scope.height) || (cov_11z8x1t1ks.b[56][1]++, '100%'),
            // Initializing Chart title
            legendSettings: {
              visible: true
            },
            tooltip: { enable: true, shared: true }
          };
        },
        _drawPieChart: function _drawPieChart(dataSource) {
          cov_11z8x1t1ks.s[105]++;

          $scope.graphParams = {
            export_: 0,
            // Initializing Series
            series: [{
              type: 'Pie', dataSource: dataSource, xName: 'l', yName: 'tr',
              dataLabel: { visible: true, position: 'Outside' }
            }], textRender: function textRender(args, a, b) {
              cov_11z8x1t1ks.s[106]++;

              if (!args.extraData) {
                cov_11z8x1t1ks.b[57][0]++;
                cov_11z8x1t1ks.s[107]++;

                args.extraData = args.text;
              } else {
                cov_11z8x1t1ks.b[57][1]++;
              }
              cov_11z8x1t1ks.s[108]++;
              args.text = $filter('number')(args.point.y) + ' %';
            },

            // title_: $scope.titleDashboard == 1 ? ($scope.analyse.TITLE || '') : '',
            legendSettings: { visible: true, position: 'Bottom' }, enableSmartLabels: true,
            // Initializing Tooltip
            tooltip: { enable: true, format: '${point.x} <br> ${point.y} %' },
            height: (cov_11z8x1t1ks.b[58][0]++, $scope.height) || (cov_11z8x1t1ks.b[58][1]++, '100%'),
            width: '100%'
          };
        },
        _processTable: function _processTable(dataGlobal, dataPeriod) {
          var _this5 = this;

          var data = (cov_11z8x1t1ks.s[109]++, []);
          cov_11z8x1t1ks.s[110]++;
          var _iteratorNormalCompletion15 = true;
          var _didIteratorError15 = false;
          var _iteratorError15 = undefined;

          try {
            var _loop5 = function _loop5() {
              var itemGlobal = _step15.value;
              cov_11z8x1t1ks.s[111]++;

              data.push({
                header: true,
                id: itemGlobal.id,
                label: '<h5>' + itemGlobal.c + ': ' + itemGlobal.l + '</h5>'
              });

              var periodData = (cov_11z8x1t1ks.s[112]++, ((cov_11z8x1t1ks.b[59][0]++, dataPeriod.find(function (periodLine) {
                cov_11z8x1t1ks.f[7]++;
                cov_11z8x1t1ks.s[113]++;

                return periodLine.id == itemGlobal.id;
              })) || (cov_11z8x1t1ks.b[59][1]++, { d: [] })).d.find(function (ele) {
                cov_11z8x1t1ks.f[8]++;
                cov_11z8x1t1ks.s[114]++;

                return ele.n == $scope.analyse.period;
              }));
              cov_11z8x1t1ks.s[115]++;
              if (periodData) {
                cov_11z8x1t1ks.b[60][0]++;
                cov_11z8x1t1ks.s[116]++;

                data.push(Object.assign({
                  label: '<div class="m-l">' + $translate.instant('INDICATOR.DASHBOARD.P') + '</div>',
                  id: itemGlobal.id + 'P'
                }, _this5._formatItem(periodData)));
              } else {
                cov_11z8x1t1ks.b[60][1]++;
              }
              var yearData = (cov_11z8x1t1ks.s[117]++, itemGlobal.ye.find(function (yearLine) {
                cov_11z8x1t1ks.f[9]++;
                cov_11z8x1t1ks.s[118]++;

                return yearLine.y == $scope.analyse.year;
              }));
              cov_11z8x1t1ks.s[119]++;
              if (yearData) {
                cov_11z8x1t1ks.b[61][0]++;
                cov_11z8x1t1ks.s[120]++;

                data.push(Object.assign({
                  label: '<div class="m-l">' + $translate.instant('INDICATOR.DASHBOARD.Y') + '</div>',
                  id: itemGlobal.id + 'Y'
                }, _this5._formatItem(yearData)));
              } else {
                cov_11z8x1t1ks.b[61][1]++;
              }

              cov_11z8x1t1ks.s[121]++;
              data.push(Object.assign({
                label: '<div class="m-l">' + $translate.instant('INDICATOR.DASHBOARD.G') + '</div>',
                id: itemGlobal.id + 'G'
              }, _this5._formatItem(itemGlobal)));
            };

            for (var _iterator15 = dataGlobal[Symbol.iterator](), _step15; !(_iteratorNormalCompletion15 = (_step15 = _iterator15.next()).done); _iteratorNormalCompletion15 = true) {
              _loop5();
            }
          } catch (err) {
            _didIteratorError15 = true;
            _iteratorError15 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion15 && _iterator15.return) {
                _iterator15.return();
              }
            } finally {
              if (_didIteratorError15) {
                throw _iteratorError15;
              }
            }
          }

          var colsSize = (cov_11z8x1t1ks.s[122]++, 4);
          cov_11z8x1t1ks.s[123]++;
          $scope.gridParams = {
            dataSource: data,
            queryCellInfo: function queryCellInfo(args) {
              cov_11z8x1t1ks.s[124]++;

              if (args.data.header) {
                cov_11z8x1t1ks.b[62][0]++;
                cov_11z8x1t1ks.s[125]++;

                args.colSpan = colsSize;
                cov_11z8x1t1ks.s[126]++;
                return;
              } else {
                cov_11z8x1t1ks.b[62][1]++;
              }
              cov_11z8x1t1ks.s[127]++;
              if (args.column.field === 'tr') {
                cov_11z8x1t1ks.b[63][0]++;
                cov_11z8x1t1ks.s[128]++;

                $timeout(function () {
                  cov_11z8x1t1ks.f[10]++;
                  cov_11z8x1t1ks.s[129]++;

                  if (args.data.tr === null) {
                    cov_11z8x1t1ks.b[64][0]++;
                    cov_11z8x1t1ks.s[130]++;

                    return;
                  } else {
                    cov_11z8x1t1ks.b[64][1]++;
                  }

                  var textGraph = (cov_11z8x1t1ks.s[131]++, $deltaNumber.formatNumberToString(100 * args.data.tr, 2) + ' %');

                  var pourcentage = (cov_11z8x1t1ks.s[132]++, Math.max(Math.min(1, args.data.tr), 0) * 100);

                  cov_11z8x1t1ks.s[133]++;
                  new ej.charts.AccumulationChart({
                    annotations: [{
                      content: '<h5>' + textGraph + '</h5>', region: 'Series', x: '30px', y: '50%'
                    }],
                    palette: ['#BAF171', '#C4EAF6', '#E9B1FF', '#F49C94', '#FCF086', '#58D740', '#7DDAF8', '#CA6FEE', '#FE855F', '#F9E850', '#1EB799', '#29DAE9', '#B145F4', '#CD5145', '#EDDA2F', '#2EAB77', '#20C9B3', '#6F43EA', '#EB5757', '#EDC32F', '#66AF45', '#33ACED', '#562EBB', '#DA4A2B', '#D39F17', '#78A73D', '#208AC9', '#792AF9', '#F35634', '#B8953B', '#A5B212', '#3160BC', '#651E91', '#AE534B', '#F38434', '#12A5B2', '#5C83E7', '#911E71', '#7E3737', '#D36617', '#2F7980', '#035C88', '#401C5B', '#5E4138', '#B66225', '#0E3E53', '#093B69', '#240E35', '#3E2B11', '#B24712'],
                    series: [{
                      dataSource: [{ x: 'Jan', y: pourcentage }, { x: 'Feb', y: 100 - pourcentage }],
                      xName: 'x',
                      yName: 'y'
                    }],
                    legendSettings: { visible: false },
                    height: '48px',
                    width: '48px'
                  }).appendTo(args.cell);
                }, 2000);
              } else {
                cov_11z8x1t1ks.b[63][1]++;
              }
            },

            gridLines: 'Both',
            allowSelection: false,
            columns: [{ field: 'label', headerText: '', disableHtmlEncode: false }, { field: 'vr', headerText: $translate.instant('INDICATOR.REAL_VAL'), width: 120, textAlign: 'Right' }, { field: 'vp', headerText: $translate.instant('INDICATOR.TARGET_VAL'), width: 120, textAlign: 'Right' }, { field: 'tr', headerText: $translate.instant('INDICATOR.DASHBOARD.RATE_TARGET'), template: '<div id="${id}"></div>', width: 120 }],
            width: 'auto',
            height: '100%',
            allowTextWrap: true
          };
        }
      });
      var secondTabChart = (cov_11z8x1t1ks.s[134]++, {
        _formatItem: function _formatItem(ele) {
          cov_11z8x1t1ks.s[135]++;

          for (var key in ele) {
            cov_11z8x1t1ks.s[136]++;

            if (!angular.isArray(ele[key])) {
              cov_11z8x1t1ks.b[65][0]++;
              cov_11z8x1t1ks.s[137]++;

              continue;
            } else {
              cov_11z8x1t1ks.b[65][1]++;
            }
            cov_11z8x1t1ks.s[138]++;
            $scope.formatNumber = ele.fo;
            cov_11z8x1t1ks.s[139]++;
            ele[key][0] = $deltaNumber.formatNumber(ele[key][0], null, ele.fo);
            cov_11z8x1t1ks.s[140]++;
            ele[key][1] = $deltaNumber.formatNumber(ele[key][1], null, ele.fo);

            /*
            "type" => $value->TYPE_UNITES,
            "format" =>  $value->TYPE_UNITES == 3 ? 0 : $value->FORMAT_UNITE,
            "displayText" => $value->DISPLAY_VALUE != "WEIGHT",
            "display" => $value->DISPLAY_VALUE,
            "vp" => ValeursPossible::getAllByindicator($value)
            * */

            cov_11z8x1t1ks.s[141]++;
            ele[key].push($rootScope.displayIndicatorValue(ele[key][0], $scope.indicator.type, $scope.indicator.format, $scope.indicator.vp, $scope.indicator.display, true));
            cov_11z8x1t1ks.s[142]++;
            ele[key].push($rootScope.displayIndicatorValue(ele[key][1], $scope.indicator.type, $scope.indicator.format, $scope.indicator.vp, $scope.indicator.display, true));
          }

          cov_11z8x1t1ks.s[143]++;
          ele.c = $deltaNumber.formatNumber(ele.c, null, ele.fo);
          cov_11z8x1t1ks.s[144]++;
          ele.b = $deltaNumber.formatNumber(ele.b, null, ele.fo);

          cov_11z8x1t1ks.s[145]++;
          ele.cDisplay = $rootScope.displayIndicatorValue(ele.c, $scope.indicator.type, $scope.indicator.format, $scope.indicator.vp, $scope.indicator.display, true);
          cov_11z8x1t1ks.s[146]++;
          ele.bDisplay = $rootScope.displayIndicatorValue(ele.b, $scope.indicator.type, $scope.indicator.format, $scope.indicator.vp, $scope.indicator.display, true);

          cov_11z8x1t1ks.s[147]++;
          ele.df = ele.df == '' ? (cov_11z8x1t1ks.b[66][0]++, null) : (cov_11z8x1t1ks.b[66][1]++, Date.newDate(ele.df));
          cov_11z8x1t1ks.s[148]++;
          return ele;
        },
        process: function process() {
          var _this6 = this;

          cov_11z8x1t1ks.s[149]++;

          $CRUDService.getAll(PATHANALYSE, { get: 'single_indicator', id: $scope.analyse.ID_INDIC }, function (indicator) {
            cov_11z8x1t1ks.f[11]++;
            cov_11z8x1t1ks.s[150]++;

            $scope.indicator = indicator[0];

            cov_11z8x1t1ks.s[151]++;
            $CRUDService.getAll(PATH, {
              get: $scope.analyse.level == 1 ? (cov_11z8x1t1ks.b[67][0]++, 'tbhome_detail') : (cov_11z8x1t1ks.b[67][1]++, 'tbhome_detail_period'),
              valid: $scope.analyse.valid,
              id: $scope.analyse.ID_INDIC,
              NG: $scope.analyse.CODE_NG,
              idcl: $scope.analyse.CODE_CL,
              type: 0,
              OPS: $scope.analyse.OPS
            }, function (data) {
              cov_11z8x1t1ks.f[12]++;

              var finalData = (cov_11z8x1t1ks.s[152]++, []);
              cov_11z8x1t1ks.s[153]++;
              var _iteratorNormalCompletion16 = true;
              var _didIteratorError16 = false;
              var _iteratorError16 = undefined;

              try {
                for (var _iterator16 = data[Symbol.iterator](), _step16; !(_iteratorNormalCompletion16 = (_step16 = _iterator16.next()).done); _iteratorNormalCompletion16 = true) {
                  var item = _step16.value;
                  cov_11z8x1t1ks.s[154]++;

                  finalData.push(_this6._formatItem(item));
                }
              } catch (err) {
                _didIteratorError16 = true;
                _iteratorError16 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion16 && _iterator16.return) {
                    _iterator16.return();
                  }
                } finally {
                  if (_didIteratorError16) {
                    throw _iteratorError16;
                  }
                }
              }

              cov_11z8x1t1ks.s[155]++;
              if ($scope.analyse.TYPE == 1) {
                cov_11z8x1t1ks.b[68][0]++;
                cov_11z8x1t1ks.s[156]++;

                return _this6._processTable(finalData);
              } else {
                cov_11z8x1t1ks.b[68][1]++;
              }

              cov_11z8x1t1ks.s[157]++;
              _this6._drawGraph(finalData);
            });
          });
        },
        _drawGraph: function _drawGraph(data) {
          var dataSource = (cov_11z8x1t1ks.s[158]++, []);
          var periods = (cov_11z8x1t1ks.s[159]++, $scope.analyse.level == 1 ? (cov_11z8x1t1ks.b[69][0]++, $deltadate.getYearBetween(Date.newDate($scope.analyse.BEGIN), Date.newDate($scope.analyse.END))) : (cov_11z8x1t1ks.b[69][1]++, $deltadate.getPeriods(Date.newDate($scope.analyse.BEGIN), Date.newDate($scope.analyse.END), $scope.analyse.period)));

          // get Key
          var index = (cov_11z8x1t1ks.s[160]++, 0);
          cov_11z8x1t1ks.s[161]++;
          var _iteratorNormalCompletion17 = true;
          var _didIteratorError17 = false;
          var _iteratorError17 = undefined;

          try {
            for (var _iterator17 = data[Symbol.iterator](), _step17; !(_iteratorNormalCompletion17 = (_step17 = _iterator17.next()).done); _iteratorNormalCompletion17 = true) {
              var item = _step17.value;
              cov_11z8x1t1ks.s[162]++;

              if (item.co != $scope.analyse.location) {
                cov_11z8x1t1ks.b[70][0]++;
                cov_11z8x1t1ks.s[163]++;

                continue;
              } else {
                cov_11z8x1t1ks.b[70][1]++;
              }
              cov_11z8x1t1ks.s[164]++;
              dataSource.push({
                label: $translate.instant('INDICATOR.GLOBAL.REF'),
                actual: item.b
              });
              cov_11z8x1t1ks.s[165]++;
              var _iteratorNormalCompletion18 = true;
              var _didIteratorError18 = false;
              var _iteratorError18 = undefined;

              try {
                for (var _iterator18 = periods[Symbol.iterator](), _step18; !(_iteratorNormalCompletion18 = (_step18 = _iterator18.next()).done); _iteratorNormalCompletion18 = true) {
                  var period = _step18.value;

                  var key = (cov_11z8x1t1ks.s[166]++, (cov_11z8x1t1ks.b[71][0]++, period.name) || (cov_11z8x1t1ks.b[71][1]++, '' + period.year + period.index));
                  cov_11z8x1t1ks.s[167]++;
                  if (angular.isArray(item[key])) {
                    cov_11z8x1t1ks.b[72][0]++;
                    cov_11z8x1t1ks.s[168]++;

                    dataSource.push({
                      label: period.end ? (cov_11z8x1t1ks.b[73][0]++, $filter('date')(period.end, 'MMM') + ' - ' + period.year) : (cov_11z8x1t1ks.b[73][1]++, period.name),
                      target: item[key][0],
                      actual: item[key][1]
                    });
                    cov_11z8x1t1ks.s[169]++;
                    index++;
                  } else {
                    cov_11z8x1t1ks.b[72][1]++;
                  }
                }
              } catch (err) {
                _didIteratorError18 = true;
                _iteratorError18 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion18 && _iterator18.return) {
                    _iterator18.return();
                  }
                } finally {
                  if (_didIteratorError18) {
                    throw _iteratorError18;
                  }
                }
              }

              cov_11z8x1t1ks.s[170]++;
              dataSource[index].finalActual = item.c;
              cov_11z8x1t1ks.s[171]++;
              break;
            }
          } catch (err) {
            _didIteratorError17 = true;
            _iteratorError17 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion17 && _iterator17.return) {
                _iterator17.return();
              }
            } finally {
              if (_didIteratorError17) {
                throw _iteratorError17;
              }
            }
          }

          cov_11z8x1t1ks.s[172]++;
          $scope.graphParams = {
            locale: $LOGINService.getLang(),
            // Initializing Primary X and Y Axis
            primaryXAxis: {
              // title: 'Years',
              interval: 1,
              labelIntersectAction: 'Rotate45',
              valueType: 'Category',
              majorGridLines: { width: 0 }, minorGridLines: { width: 0 },
              majorTickLines: { width: 0 }, minorTickLines: { width: 0 },
              lineStyle: { width: 0 }
            },
            primaryYAxis: {
              valueType: 'Double',
              lineStyle: { width: 0 },
              majorTickLines: { width: 0 }, majorGridLines: { width: 1 },
              minorGridLines: { width: 1 }, minorTickLines: { width: 0 },
              labelFormat: 'N' + $scope.formatNumber
            },
            chartArea: {
              border: {
                width: 0
              }
            },
            useGroupingSeparator: true,
            // Initializing Chart Series
            series: [{
              type: $scope.analyse.target.barChart ? (cov_11z8x1t1ks.b[74][0]++, 'Column') : (cov_11z8x1t1ks.b[74][1]++, $scope.analyse.FILL ? (cov_11z8x1t1ks.b[75][0]++, 'Area') : (cov_11z8x1t1ks.b[75][1]++, 'Line')),
              opacity: $scope.analyse.target.barChart ? (cov_11z8x1t1ks.b[76][0]++, 1) : (cov_11z8x1t1ks.b[76][1]++, 0.5),
              dataSource: dataSource,
              fill: 'rgba(' + $scope.analyse.target.color + ', 0.7)',
              xName: 'label', yName: 'actual', name: $translate.instant('INDICATOR.REAL_VAL'),
              width: 3,
              marker: $scope.analyse.target.barChart ? (cov_11z8x1t1ks.b[77][0]++, undefined) : (cov_11z8x1t1ks.b[77][1]++, {
                visible: true,
                width: 10,
                height: 10
              })
            }, {
              type: $scope.analyse.actual.barChart ? (cov_11z8x1t1ks.b[78][0]++, 'Column') : (cov_11z8x1t1ks.b[78][1]++, $scope.analyse.FILL ? (cov_11z8x1t1ks.b[79][0]++, 'Area') : (cov_11z8x1t1ks.b[79][1]++, 'Line')),
              opacity: $scope.analyse.actual.barChart ? (cov_11z8x1t1ks.b[80][0]++, 1) : (cov_11z8x1t1ks.b[80][1]++, 0.5),
              dataSource: dataSource,
              fill: 'rgba(' + $scope.analyse.actual.color + ', 0.7)',
              xName: 'label', yName: 'target', name: $translate.instant('INDICATOR.TARGET_VAL'),
              width: 3,
              marker: $scope.analyse.actual.barChart ? (cov_11z8x1t1ks.b[81][0]++, undefined) : (cov_11z8x1t1ks.b[81][1]++, {
                visible: true,
                width: 10,
                height: 10
              })
            }, {
              type: 'Line',
              opacity: 0.5,
              dataSource: dataSource,
              fill: 'rgba(2013, 76, 101, 0.7)',
              xName: 'label', yName: 'finalActual', name: $translate.instant('INDICATOR.GLOBAL.TARGET'),
              width: 3,
              marker: {
                visible: true,
                width: 10,
                height: 10
              }
            }],
            textRender: function textRender(args, a, b) {},

            width: '100%',
            height: (cov_11z8x1t1ks.b[82][0]++, $scope.height) || (cov_11z8x1t1ks.b[82][1]++, '100%'),
            // Initializing Chart title
            legendSettings: {
              visible: true
            },
            tooltip: { enable: true, shared: true }
          };
        },
        _processPeriod: function _processPeriod() {},
        _processTable: function _processTable(data) {
          var periods = (cov_11z8x1t1ks.s[173]++, $scope.analyse.level == 1 ? (cov_11z8x1t1ks.b[83][0]++, $deltadate.getYearBetween(Date.newDate($scope.analyse.BEGIN), Date.newDate($scope.analyse.END))) : (cov_11z8x1t1ks.b[83][1]++, $deltadate.getPeriods(Date.newDate($scope.analyse.BEGIN), Date.newDate($scope.analyse.END), $scope.analyse.period)));

          var columns = (cov_11z8x1t1ks.s[174]++, [{
            field: 'lo', headerText: $translate.instant('INDICATOR.GLOBAL.LOCATION'), width: 200
          }, {
            field: $scope.indicator.displayText ? (cov_11z8x1t1ks.b[84][0]++, 'bDisplay') : (cov_11z8x1t1ks.b[84][1]++, 'b'), headerText: $translate.instant('INDICATOR.GLOBAL.REF'), width: 120,
            textAlign: $scope.indicator.displayText ? (cov_11z8x1t1ks.b[85][0]++, 'Left') : (cov_11z8x1t1ks.b[85][1]++, 'Right'), type: $scope.indicator.displayText ? (cov_11z8x1t1ks.b[86][0]++, 'Text') : (cov_11z8x1t1ks.b[86][1]++, 'Number')
          }]);
          cov_11z8x1t1ks.s[175]++;
          var _iteratorNormalCompletion19 = true;
          var _didIteratorError19 = false;
          var _iteratorError19 = undefined;

          try {
            for (var _iterator19 = periods[Symbol.iterator](), _step19; !(_iteratorNormalCompletion19 = (_step19 = _iterator19.next()).done); _iteratorNormalCompletion19 = true) {
              var period = _step19.value;

              var key = (cov_11z8x1t1ks.s[176]++, (cov_11z8x1t1ks.b[87][0]++, period.name) || (cov_11z8x1t1ks.b[87][1]++, '' + period.year + period.index));
              cov_11z8x1t1ks.s[177]++;
              columns.push({
                field: $scope.indicator.displayText ? (cov_11z8x1t1ks.b[88][0]++, key + '.3') : (cov_11z8x1t1ks.b[88][1]++, key + '.1'),
                headerTextAlign: 'Center',
                textAlign: $scope.indicator.displayText ? (cov_11z8x1t1ks.b[89][0]++, 'Left') : (cov_11z8x1t1ks.b[89][1]++, 'Right'), type: $scope.indicator.displayText ? (cov_11z8x1t1ks.b[90][0]++, 'Text') : (cov_11z8x1t1ks.b[90][1]++, 'Number'),
                headerText: period.end ? (cov_11z8x1t1ks.b[91][0]++, $filter('date')(period.end, 'MMM') + ' - ' + period.year) : (cov_11z8x1t1ks.b[91][1]++, period.name),
                width: 120
              });
            }
          } catch (err) {
            _didIteratorError19 = true;
            _iteratorError19 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion19 && _iterator19.return) {
                _iterator19.return();
              }
            } finally {
              if (_didIteratorError19) {
                throw _iteratorError19;
              }
            }
          }

          cov_11z8x1t1ks.s[178]++;
          columns.push({
            field: 'rate', headerText: $translate.instant('DASHBOARD.REALIZE'),
            width: 130, template: '<div id="${co}"></div>'
          });

          cov_11z8x1t1ks.s[179]++;
          columns.push({
            field: $scope.indicator.displayText ? (cov_11z8x1t1ks.b[92][0]++, 'cDisplay') : (cov_11z8x1t1ks.b[92][1]++, 'c'), headerText: $translate.instant('INDICATOR.GLOBAL.TARGET'),
            width: 120,
            textAlign: $scope.indicator.displayText ? (cov_11z8x1t1ks.b[93][0]++, 'Left') : (cov_11z8x1t1ks.b[93][1]++, 'Right'), type: $scope.indicator.displayText ? (cov_11z8x1t1ks.b[94][0]++, 'Text') : (cov_11z8x1t1ks.b[94][1]++, 'Number')
          });

          cov_11z8x1t1ks.s[180]++;
          columns.push({
            field: 'df', headerText: $translate.instant('INDICATOR.GLOBAL.END'), width: 120,
            format: { type: 'date', skeleton: 'medium' }
          });

          cov_11z8x1t1ks.s[181]++;
          $scope.gridParams = {
            dataSource: data,
            queryCellInfo: function queryCellInfo(args) {
              cov_11z8x1t1ks.s[182]++;

              /* if (args.data.header) {
                args.colSpan = colsSize;
                return;
              } */
              if (args.column.field === 'rate') {
                cov_11z8x1t1ks.b[95][0]++;
                cov_11z8x1t1ks.s[183]++;

                // $timeout(() => {

                if (args.data.c === null) {
                  cov_11z8x1t1ks.b[96][0]++;
                  cov_11z8x1t1ks.s[184]++;

                  return;
                } else {
                  cov_11z8x1t1ks.b[96][1]++;
                }
                var rate = (cov_11z8x1t1ks.s[185]++, $deltaNumber.formatNumber(args.data.T[1] / args.data.c, null, 4));
                var textGraph = (cov_11z8x1t1ks.s[186]++, $deltaNumber.formatNumberToString(100 * rate, 2) + ' %');

                var pourcentage = (cov_11z8x1t1ks.s[187]++, Math.max(Math.min(1, rate), 0) * 100);

                cov_11z8x1t1ks.s[188]++;
                $timeout(function () {
                  cov_11z8x1t1ks.f[13]++;
                  cov_11z8x1t1ks.s[189]++;

                  new ej.charts.AccumulationChart({
                    palette: ['#BAF171', '#C4EAF6', '#E9B1FF', '#F49C94', '#FCF086', '#58D740', '#7DDAF8', '#CA6FEE', '#FE855F', '#F9E850', '#1EB799', '#29DAE9', '#B145F4', '#CD5145', '#EDDA2F', '#2EAB77', '#20C9B3', '#6F43EA', '#EB5757', '#EDC32F', '#66AF45', '#33ACED', '#562EBB', '#DA4A2B', '#D39F17', '#78A73D', '#208AC9', '#792AF9', '#F35634', '#B8953B', '#A5B212', '#3160BC', '#651E91', '#AE534B', '#F38434', '#12A5B2', '#5C83E7', '#911E71', '#7E3737', '#D36617', '#2F7980', '#035C88', '#401C5B', '#5E4138', '#B66225', '#0E3E53', '#093B69', '#240E35', '#3E2B11', '#B24712'],
                    annotations: [{
                      content: '<h5>' + textGraph + '</h5>', region: 'Series', x: '60px', y: '50%'
                    }],
                    series: [{
                      dataSource: [{ x: 'Jan', y: pourcentage }, { x: 'Feb', y: 100 - pourcentage }],
                      xName: 'x',
                      yName: 'y'
                    }],
                    legendSettings: { visible: false },
                    height: '48px',
                    width: '48px'
                  }).appendTo('#' + args.data.co);
                }, 2000);
              } else {
                cov_11z8x1t1ks.b[95][1]++;
              }
            },

            gridLines: 'Both',
            allowSelection: false,
            columns: columns,
            width: 'auto',
            height: '100%',
            allowTextWrap: true
          };

          /* const data = [];
          for(const itemGlobal of dataGlobal ){
            data.push({
              header: true,
              id: itemGlobal.id,
              label: `<h5>${itemGlobal.c}: ${itemGlobal.l}</h5>`
            });
             const periodData = (dataPeriod.find(periodLine => {
              return periodLine.id  == itemGlobal.id;
            }) || {d: []}).d.find(ele => {
              return ele.n == $scope.analyse.period;
            });
            if(periodData){
              data.push(Object.assign({
                label:  `<div class="m-l">${$translate.instant('INDICATOR.DASHBOARD.P')}</div>`,
                id: itemGlobal.id + 'P'
              }, this._formatItem(periodData)));
            }
            const yearData = itemGlobal.ye.find(yearLine => {
              return yearLine.y == $scope.analyse.year;
            });
            if(yearData){
              data.push(Object.assign({
                label:  `<div class="m-l">${$translate.instant('INDICATOR.DASHBOARD.Y')}</div>`,
                id: itemGlobal.id + 'Y'
              }, this._formatItem(yearData)));
            }
             data.push(Object.assign({
              label:  `<div class="m-l">${$translate.instant('INDICATOR.DASHBOARD.G')}</div>`,
              id: itemGlobal.id + 'G'
            }, this._formatItem(itemGlobal)))
           }
          const colsSize = 4;
          $scope.gridParams = {
            dataSource: data,
            queryCellInfo(args) {
              if (args.data.header) {
                args.colSpan = colsSize;
                return;
              }
              if (args.column.field === 'tr') {
                 $timeout(() => {
                   if(args.data.tr === null){
                    return;
                  }
                   const textGraph = $deltaNumber.formatNumberToString(100 * args.data.tr, 2) + ' %';
                   const pourcentage = Math.max( Math.min(1, args.data.tr), 0) * 100;
                   (new ej.charts.AccumulationChart({
                    annotations: [{
                      content: '<h5>'+textGraph+'</h5>', region: 'Series', x: '30px', y: '50%'
                    }],
                    series: [{
                      dataSource: [{ x: 'Jan', y: pourcentage }, { x: 'Feb', y: 100 - pourcentage}],
                      xName: 'x',
                      yName: 'y'
                    }],
                    legendSettings:{visible: false},
                    height: '48px',
                    width: '48px',
                  })).appendTo(args.cell);
                }, 2000);
              }
            },
            gridLines: 'Both',
            allowSelection: false,
            columns: [
              {field: 'label', headerText: '', disableHtmlEncode: false},
              {field: 'vr', headerText: $translate.instant('INDICATOR.REAL_VAL'), width: 120, textAlign: 'Right'},
              {field: 'vp', headerText: $translate.instant('INDICATOR.TARGET_VAL'), width: 120, textAlign: 'Right'},
              {field: 'tr', headerText: '', template: '<div id="${id}"></div>', width: 120}
            ],
            width: 'auto',
            height: '100%',
            allowTextWrap: true
          } */
        }
      });
      cov_11z8x1t1ks.s[190]++;
      if ($scope.analyse.typeAnalyse >= 3) {
        cov_11z8x1t1ks.b[97][0]++;
        cov_11z8x1t1ks.s[191]++;

        secondTabChart.process();
      } else {
        cov_11z8x1t1ks.b[97][1]++;
        cov_11z8x1t1ks.s[192]++;

        firstTabChart.process();
      }
    }
  };
});